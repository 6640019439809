import { ReactNode } from 'react';
import { config as configApp } from 'config/config';
import { selectConfig } from 'config/countries';
import { isValidEmail } from '../../../../validations/isValidEmail';
import {
  emptyValue,
  hasSpecialCharacters,
  hasValue,
} from '../../../../validations/helpers';
import {
  getPhonePlatformsValidatorsAndProperties,
  getPhonePlatformsValidatorsAndPropertiesDefault,
} from '../../../../validations/getPhonePlatformsValidatorsAndProperties';
import { CustomFormatMessage, isUSAPlatform } from '../../../../../utils';
import { registerSpecialityCollegiateLength } from '../../../../shared/enums';

const { PLATFORM } = configApp.APP;
const specialityCollegiateLength = registerSpecialityCollegiateLength[PLATFORM];

export const getDefaultValueResidenceCountry = () => {
  if (typeof window === 'undefined') return null;

  if (window.location.search.includes('country=')) {
    const value = window.location.search.split('country=')[1];
    return value[0] + value[1];
  }
  return (PLATFORM[4] + PLATFORM[5]).toUpperCase();
};

type Properties = {
  maxLength?: number;
  required?: boolean;
  errorMessage?: ReactNode;
  colectiveValidate?: boolean;
  oneWordInput?: boolean;
  optional?: boolean;
  callbackValidate?: (id: string, errors: ReactNode[]) => void;
  helperMessages?: ReactNode[];
  icon?: string;
  options?: {
    label: string;
    value: string;
  }[];
  defaultValue?: string;
};

export const config: Record<
  | 'email'
  | 'password'
  | 'terms_conditions'
  | 'privacy_policy'
  | 'newsletter_subscription'
  | 'residence_country'
  | 'name'
  | 'lastname'
  | 'document_id'
  | 'specialty'
  | 'collegiate_number'
  | 'resident'
  | 'graduation_year'
  | 'work_center'
  | 'street'
  | 'street_details'
  | 'zip_code'
  | 'town'
  | 'state'
  | 'country'
  | 'telephone'
  | 'medical_center'
  | 'plate_type'
  | 'plate_number'
  | 'odontology_name'
  | 'odontology_collegiate_number',
  {
    inputClass: 'checkbox' | 'text' | 'select' | 'radio';
    label?: string;
    validators?: {
      validator: (value: string) => boolean;
      message: ReactNode;
    }[];
    properties?: Properties;
    platform?: Record<
      string,
      {
        validators?: {
          validator: (value: string) => boolean;
          message: ReactNode;
        }[];
        properties?: Properties;
        maxLength?: number;
      }
    >;
  }
> = {
  email: {
    inputClass: 'text',
    label: 'form.field.email.label',
    validators: [
      {
        validator: (value) => hasValue(value),
        message: <CustomFormatMessage id="form.message.required-email" />,
      },
      {
        validator: (value) => {
          if (hasValue(value)) {
            return isValidEmail(value);
          }
          return true;
        },
        message: <CustomFormatMessage id="form.message.incorrect-email" />,
      },
    ],
    properties: {
      maxLength: 50,
      oneWordInput: true,
    },
  },
  password: {
    inputClass: 'text',
    label: 'form.field.password',
    validators: [
      {
        validator: (value) => value.length >= 8,
        message: (
          <CustomFormatMessage id="page.password-change.validation-length" />
        ),
      },
      {
        validator: (value) => /((?=.*[A-Z]).{1,20})/g.test(value),
        message: (
          <CustomFormatMessage id="page.password-change.validation-uppercase-letters" />
        ),
      },
      {
        validator: (value) => /((?=.*[a-z]).{1,20})/g.test(value),
        message: (
          <CustomFormatMessage id="page.password-change.validation-lowercase-letters" />
        ),
      },
      {
        validator: (value) => /((?=.*\d).{1,20})/g.test(value),
        message: (
          <CustomFormatMessage id="page.password-change.validation-number" />
        ),
      },
    ],
    properties: {
      maxLength: 30,
    },
  },

  terms_conditions: {
    inputClass: 'checkbox',
    label: 'register.form.terms-conditions.only',
    properties: {
      required: true,
      errorMessage: (
        <CustomFormatMessage id="register.form.terms-conditions.is-required" />
      ),
      helperMessages: isUSAPlatform
        ? []
        : [<CustomFormatMessage id="register.form.terms-conditions.text" />],
    },
  },
  privacy_policy: {
    inputClass: 'checkbox',
    label: 'register.form.privacy-policy.only',
    properties: {
      required: true,
      errorMessage: (
        <CustomFormatMessage id="register.form.privacy-policy.is-required" />
      ),
    },
    platform: {},
  },
  newsletter_subscription: {
    inputClass: 'checkbox',
    label: 'register.form.newsletter-subscription',
  },
  residence_country: {
    inputClass: 'select',
    label: 'form.field.residence-country',
    properties: {
      defaultValue: getDefaultValueResidenceCountry(),
      options: selectConfig[configApp.APP.PLATFORM],
      icon: 'world',
    },
  },
  name: {
    inputClass: 'text',
    label: 'form.field.name',
    validators: [
      {
        validator: (value) => hasValue(value),
        message: (
          <CustomFormatMessage id="form.message.name.invalid-required" />
        ),
      },
      {
        validator: (value) => /^[^*/;#|€$"'~{}[\]]*$/.test(value),
        message: <CustomFormatMessage id="form.message.name.invalid-format" />,
      },
    ],
    properties: {
      maxLength: 30,
    },
  },
  lastname: {
    inputClass: 'text',
    label: 'form.field.last-name',
    validators: [
      {
        validator: (value) => hasValue(value),
        message: (
          <CustomFormatMessage id="form.message.last-name.invalid-required" />
        ),
      },
      {
        validator: (value) => /^[^*/;#|€$"'~{}[\]]*$/.test(value),
        message: (
          <CustomFormatMessage id="form.message.last-name.invalid-format" />
        ),
      },
    ],
    properties: {
      maxLength: 30,
    },
  },
  document_id: {
    inputClass: 'text',
    label: 'form.field.user-document',
    platform: {
      ES: {
        validators: [
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.message.user-document.invalid-required" />
            ),
          },
          {
            validator: (value) => {
              if (emptyValue(value)) return true;

              const REGEX = /^[0-9XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/;
              const validLastLetter = 'TRWAGMYFPDXBNJZSQVHLCKE';
              const valueUpperCase = value.toString().toUpperCase();

              if (!REGEX.test(valueUpperCase)) return false;

              const nie = valueUpperCase
                .replace(/^[X]/, '0')
                .replace(/^[Y]/, '1')
                .replace(/^[Z]/, '2');

              const letter = valueUpperCase.slice(-1);
              const charIndex = parseInt(nie.substring(0, 8)) % 23;

              return validLastLetter.charAt(charIndex) === letter;
            },
            message: (
              <CustomFormatMessage id="form.message.user-document.invalid-format" />
            ),
          },
        ],
        properties: {
          maxLength: 9,
          oneWordInput: true,
        },
      },
      AD: {
        validators: [
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.message.user-document.invalid-required" />
            ),
          },
          {
            validator: (value: string) => {
              if (emptyValue(value)) return true;
              const REGEX = /^[a-zA-Z]\d{6}[a-zA-Z]$/;
              const valueUpperCase = value.toString().toUpperCase();
              return REGEX.test(valueUpperCase);
            },
            message: (
              <CustomFormatMessage id="form.message.user-document.invalid-format" />
            ),
          },
        ],
        properties: {
          maxLength: 8,
          oneWordInput: true,
        },
      },
      AR: {
        validators: [
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.message.user-document.invalid-required" />
            ),
          },
          {
            validator: (value) => !hasSpecialCharacters(value),
            message: <CustomFormatMessage id="form.message.incorrect-format" />,
          },
        ],
        properties: {
          maxLength: 12,
          oneWordInput: true,
        },
      },
      PE: {
        validators: [
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.message.user-document.invalid-required" />
            ),
          },
          {
            validator: (value) => !hasSpecialCharacters(value),
            message: <CustomFormatMessage id="form.message.incorrect-format" />,
          },
        ],
        properties: {
          maxLength: 12,
          oneWordInput: true,
        },
      },
      CO: {
        validators: [
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.message.user-document.invalid-required" />
            ),
          },
          {
            validator: (value) => !hasSpecialCharacters(value),
            message: <CustomFormatMessage id="form.message.incorrect-format" />,
          },
        ],
        properties: {
          maxLength: 10,
          oneWordInput: true,
        },
      },
      GT: {
        validators: [
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.message.user-document.invalid-required" />
            ),
          },
          {
            validator: (value) => !hasSpecialCharacters(value),
            message: <CustomFormatMessage id="form.message.incorrect-format" />,
          },
        ],
        properties: {
          maxLength: 18,
          oneWordInput: true,
        },
      },
      CL: {
        validators: [
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.message.user-document.invalid-required" />
            ),
          },
          {
            validator: (value) => {
              const REGEX = /^\d{2}\.\d{3}\.\d{3}-[0-9a-zA-Z]$/;

              return REGEX.test(value.toString());
            },
            message: (
              <CustomFormatMessage id="form.message.user-document.invalid-format" />
            ),
          },
        ],
        properties: {
          maxLength: 12,
          oneWordInput: true,
          helperMessages: [
            <CustomFormatMessage id="register.form.id_documento.help-message" />,
          ],
        },
      },
      IT: {
        validators: [
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.message.user-document.invalid-required" />
            ),
          },
          {
            validator: (value) => !hasSpecialCharacters(value),
            message: <CustomFormatMessage id="form.message.incorrect-format" />,
          },
        ],
        properties: {
          required: true,
          maxLength: 20,
          oneWordInput: true,
        },
      },
      VA: {
        validators: [
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.message.user-document.invalid-required" />
            ),
          },
          {
            validator: (value) => !hasSpecialCharacters(value),
            message: <CustomFormatMessage id="form.message.incorrect-format" />,
          },
        ],
        properties: {
          required: true,
          maxLength: 20,
          oneWordInput: true,
        },
      },
      SM: {
        validators: [
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.message.user-document.invalid-required" />
            ),
          },
          {
            validator: (value) => !hasSpecialCharacters(value),
            message: <CustomFormatMessage id="form.message.incorrect-format" />,
          },
        ],
        properties: {
          required: true,
          maxLength: 20,
          oneWordInput: true,
        },
      },
      BR: {
        validators: [
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.message.user-document.invalid-required" />
            ),
          },
          {
            validator: (value) => {
              if (emptyValue(value)) return true;
              const REGEX = /^\d{11}$/;
              return REGEX.test(value.toString());
            },
            message: (
              <CustomFormatMessage id="form.message.user-document.invalid-format" />
            ),
          },
        ],
        properties: {
          maxLength: 11,
          oneWordInput: true,
        },
      },
      PT: {
        validators: [
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.message.user-document.invalid-required" />
            ),
          },
          {
            validator: (value) => {
              if (!value || value.trim() === '') {
                return true;
              }

              return value.length === 9;
            },
            message: (
              <CustomFormatMessage id="form.message.user-document.invalid-format" />
            ),
          },
        ],
        properties: {
          maxLength: 9,
          oneWordInput: true,
        },
      },
      MX: {
        validators: [
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.message.user-document.invalid-required" />
            ),
          },
          {
            validator: (value) => !hasSpecialCharacters(value),
            message: <CustomFormatMessage id="form.message.incorrect-format" />,
          },
        ],
        properties: {
          maxLength: 18,
          oneWordInput: true,
        },
      },
      DE: {
        validators: [],
        properties: {
          maxLength: 10,
          oneWordInput: true,
        },
      },
      AT: {
        validators: [],
        properties: {
          maxLength: 10,
          oneWordInput: true,
        },
      },
    },
  },
  specialty: {
    inputClass: 'select',
    label: 'form.field.specialty',
    properties: {
      required: true,
      errorMessage: (
        <CustomFormatMessage id="register.form.specialty.is-required" />
      ),
    },
  },
  collegiate_number: {
    inputClass: 'text',
    label: 'form.field.collegiate-number',
    platform: {
      US: {
        validators: [
          {
            validator: (value) => !hasSpecialCharacters(value),
            message: <CustomFormatMessage id="form.message.incorrect-format" />,
          },
        ],
        properties: {
          maxLength: 20,
          optional: true,
          oneWordInput: true,
        },
      },
      ES: {
        validators: [
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.field.collegiate-number.is-required" />
            ),
          },
          {
            validator: function (value) {
              if (
                hasValue(value) &&
                specialityCollegiateLength &&
                specialityCollegiateLength[this.currentSpecialty] &&
                value.length ===
                  specialityCollegiateLength[this.currentSpecialty].maxLength
              ) {
                return /^\d+$/.test(value);
              }
              return false;
            },
            message: (
              <CustomFormatMessage id="form.field.collegiate-number.format" />
            ),
          },
        ],
        properties: {
          maxLength: 9,
          optional: false,
          oneWordInput: true,
        },
      },
      AD: {
        validators: [
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.field.collegiate-number.is-required" />
            ),
          },
          {
            validator: (value) => {
              if (hasValue(value)) {
                return /^\d+$/.test(value);
              }
              return true;
            },
            message: (
              <CustomFormatMessage id="form.field.collegiate-number.format" />
            ),
          },
        ],
        properties: {
          maxLength: 20,
          optional: false,
          oneWordInput: true,
        },
      },
      AR: {
        validators: [
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.field.collegiate-number.is-required" />
            ),
          },
          {
            validator: (value) => {
              if (hasValue(value)) {
                return /^\d+$/.test(value);
              }
              return true;
            },
            message: (
              <CustomFormatMessage id="form.field.collegiate-number.format" />
            ),
          },
        ],
        properties: {
          maxLength: 20,
          optional: false,
          oneWordInput: true,
        },
      },
      PE: {
        validators: [
          {
            validator: (value) => {
              if (hasValue(value)) {
                return /^\d+$/.test(value);
              }
              return true;
            },
            message: (
              <CustomFormatMessage id="form.field.collegiate-number.format" />
            ),
          },
        ],
        properties: {
          maxLength: 20,
          optional: true,
          oneWordInput: true,
        },
      },
      CO: {
        validators: [
          {
            validator: (value) => !hasSpecialCharacters(value),
            message: <CustomFormatMessage id="form.message.incorrect-format" />,
          },
        ],
        properties: {
          maxLength: 20,
          optional: true,
          oneWordInput: true,
        },
      },
      GT: {
        validators: [
          {
            validator: (value) => !hasSpecialCharacters(value),
            message: <CustomFormatMessage id="form.message.incorrect-format" />,
          },
        ],
        properties: {
          maxLength: 20,
          optional: true,
          oneWordInput: true,
        },
      },
      CL: {
        validators: [
          {
            validator: (value) => {
              if (hasValue(value)) {
                return /^\d+$/.test(value);
              }
              return true;
            },
            message: (
              <CustomFormatMessage id="form.field.collegiate-number.format" />
            ),
          },
        ],
        properties: {
          maxLength: 20,
          optional: true,
          oneWordInput: true,
        },
      },
      BR: {
        validators: [
          {
            validator: (value) => {
              if (hasValue(value)) {
                const REGEX = /^[a-zA-Z]{2}\d{7}$/;

                return REGEX.test(value.toString());
              }
            },
            message: (
              <CustomFormatMessage id="form.field.collegiate-number.format" />
            ),
          },
        ],
        properties: {
          maxLength: 9,
          required: true,
          oneWordInput: true,
          helperMessages: [
            <CustomFormatMessage id="forms.register.collegiate-info" />,
          ],
        },
      },
      MX: {
        validators: [
          {
            validator: function (value) {
              return hasValue(value);
            },
            message: (
              <CustomFormatMessage id="form.field.collegiate-number.is-required" />
            ),
          },
          {
            validator: function (value) {
              if (this.isOdontoestomatologo) {
                return value.length >= 7 || value.length === 0;
              } else {
                return value.length <= 8;
              }
            },
            message: (
              <CustomFormatMessage id="form.field.collegiate-number.format" />
            ),
          },
        ],
        properties: {
          maxLength: 8,
          optional: false,
          oneWordInput: true,
        },
      },
      PT: {
        validators: [
          {
            validator: (value) => {
              if (hasValue(value)) {
                return /^\d{5}$/.test(value);
              }
              return true;
            },
            message: (
              <CustomFormatMessage id="form.field.collegiate-number.format" />
            ),
          },
        ],
        properties: {
          maxLength: 5,
          optional: true,
          oneWordInput: true,
        },
      },
      IT: {
        validators: [
          {
            validator: (value) => {
              return !(value.length < 4);
            },
            message: (
              <CustomFormatMessage id="form.field.collegiate-number.min-length" />
            ),
          },
          {
            validator: (value) => !hasSpecialCharacters(value),
            message: <CustomFormatMessage id="form.message.incorrect-format" />,
          },
        ],
        properties: {
          maxLength: 7,
          optional: false,
          oneWordInput: true,
        },
      },
      VA: {
        validators: [
          {
            validator: (value) => {
              return !(value.length < 4);
            },
            message: (
              <CustomFormatMessage id="form.field.collegiate-number.min-length" />
            ),
          },
          {
            validator: (value) => !hasSpecialCharacters(value),
            message: <CustomFormatMessage id="form.message.incorrect-format" />,
          },
        ],
        properties: {
          maxLength: 7,
          optional: false,
          oneWordInput: true,
        },
      },
      SM: {
        validators: [
          {
            validator: (value) => {
              return !(value.length < 4);
            },
            message: (
              <CustomFormatMessage id="form.field.collegiate-number.min-length" />
            ),
          },
          {
            validator: (value) => !hasSpecialCharacters(value),
            message: <CustomFormatMessage id="form.message.incorrect-format" />,
          },
        ],
        properties: {
          maxLength: 7,
          optional: false,
          oneWordInput: true,
        },
      },
      DE: {
        validators: [
          {
            validator: (value) => {
              return !(value.length < 15);
            },
            message: (
              <CustomFormatMessage id="form.field.collegiate-number.min-length" />
            ),
          },
          {
            validator: (value) => !hasSpecialCharacters(value),
            message: <CustomFormatMessage id="form.message.incorrect-format" />,
          },
        ],
        properties: {
          oneWordInput: true,
          maxLength: 15,
        },
      },
      AT: {
        validators: [
          {
            validator: (value) => {
              return !(value.length < 15);
            },
            message: (
              <CustomFormatMessage id="form.field.collegiate-number.min-length" />
            ),
          },
          {
            validator: (value) => !hasSpecialCharacters(value),
            message: <CustomFormatMessage id="form.message.incorrect-format" />,
          },
        ],
        properties: {
          oneWordInput: true,
          maxLength: 15,
        },
      },
    },
  },
  resident: {
    inputClass: 'radio',
    label: 'form.field.user-is-resident',
  },
  graduation_year: {
    inputClass: 'text',
    label: 'form.field.graduation-year',
    validators: [
      {
        validator: (value) => hasValue(value),
        message: (
          <CustomFormatMessage id="form.field.graduation-year.is-required" />
        ),
      },
      {
        validator: (value) => {
          if (hasValue(value)) {
            const numericValue = Number(value);
            const currentYear = new Date().getFullYear();
            return (
              currentYear - 10 <= numericValue && numericValue <= currentYear
            );
          }
          return true;
        },
        message: <CustomFormatMessage id="form.field.graduation-year.format" />,
      },
    ],
    properties: {
      maxLength: 4,
      oneWordInput: true,
    },
  },
  work_center: {
    inputClass: 'text',
    label: 'form.field.work-center',
    validators: [
      {
        validator: (value) => hasValue(value),
        message: (
          <CustomFormatMessage id="form.message.work-center.invalid-required" />
        ),
      },
      {
        validator: (value) => !hasSpecialCharacters(value),
        message: <CustomFormatMessage id="form.message.incorrect-format" />,
      },
    ],
    properties: {
      maxLength: 30,
    },
  },
  street: {
    inputClass: 'text',
    label: 'form.field.street',
    validators: [
      {
        validator: (value) => hasValue(value),
        message: (
          <CustomFormatMessage id="form.field.addressStreetNumber-is-required" />
        ),
      },
      {
        validator: (value) => !hasSpecialCharacters(value),
        message: <CustomFormatMessage id="form.message.incorrect-format" />,
      },
    ],
    properties: {
      maxLength: 30,
      icon: 'pin-map-marker',
      helperMessages: [<CustomFormatMessage id="forms.register.street-info" />],
    },
  },
  street_details: {
    inputClass: 'text',
    label: 'form.field.street-details',
    validators: [
      {
        validator: (value) => hasValue(value),
        message: (
          <CustomFormatMessage id="form.field.street-details.is-required" />
        ),
      },
      {
        validator: (value) => !hasSpecialCharacters(value),
        message: <CustomFormatMessage id="form.message.incorrect-format" />,
      },
    ],
    properties: {
      maxLength: 10,
      helperMessages: [<CustomFormatMessage id="forms.register.adress-info" />],
    },
  },
  zip_code: {
    inputClass: 'text',
    label: 'form.address.zipcode',
    platform: {
      US: {
        validators: [
          {
            validator: (value) => {
              if (hasValue(value)) {
                return /^\d{5}$/.test(value);
              }
              return true;
            },
            message: <CustomFormatMessage id="form.address.error-format" />,
          },
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.field.zipCode-is-required" />
            ),
          },
        ],
        properties: {
          colectiveValidate: true,
          maxLength: 5,
          oneWordInput: true,
        },
      },
      ES: {
        validators: [
          {
            validator: (value) => {
              if (hasValue(value)) {
                return /^\d{5}$/.test(value);
              }
              return true;
            },
            message: <CustomFormatMessage id="form.address.error-format" />,
          },
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.field.zipCode-is-required" />
            ),
          },
        ],
        properties: {
          colectiveValidate: true,
          maxLength: 5,
          oneWordInput: true,
        },
      },
      AD: {
        validators: [
          {
            validator: (value) => {
              if (hasValue(value)) {
                return /^(AD|ad)\d{3}$/i.test(value);
              }
              return true;
            },
            message: <CustomFormatMessage id="form.address.error-format" />,
          },
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.field.zipCode-is-required" />
            ),
          },
        ],
        properties: {
          colectiveValidate: true,
          maxLength: 5,
          oneWordInput: true,
        },
      },
      AR: {
        validators: [
          {
            validator: (value) => {
              if (hasValue(value)) {
                return /^\d{4}$/i.test(value);
              }
              return true;
            },
            message: <CustomFormatMessage id="form.address.error-format" />,
          },
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.field.zipCode-is-required" />
            ),
          },
        ],
        properties: {
          colectiveValidate: true,
          maxLength: 4,
          oneWordInput: true,
        },
      },
      CO: {
        validators: [
          {
            validator: (value) => {
              if (hasValue(value)) {
                return /^\d{4}$/i.test(value);
              }
              return true;
            },
            message: <CustomFormatMessage id="form.address.error-format" />,
          },
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.field.zipCode-is-required" />
            ),
          },
        ],
        properties: {
          colectiveValidate: true,
          maxLength: 4,
          oneWordInput: true,
        },
      },
      GT: {
        validators: [
          {
            validator: (value) => {
              if (hasValue(value)) {
                return /^\d{4}$/i.test(value);
              }
              return true;
            },
            message: <CustomFormatMessage id="form.address.error-format" />,
          },
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.field.zipCode-is-required" />
            ),
          },
        ],
        properties: {
          colectiveValidate: true,
          maxLength: 4,
          oneWordInput: true,
        },
      },
      PE: {
        validators: [
          {
            validator: (value) => {
              if (hasValue(value)) {
                return /^\d{4}$/i.test(value);
              }
              return true;
            },
            message: <CustomFormatMessage id="form.address.error-format" />,
          },
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.field.zipCode-is-required" />
            ),
          },
        ],
        properties: {
          colectiveValidate: true,
          maxLength: 4,
          oneWordInput: true,
        },
      },
      CL: {
        validators: [
          {
            validator: (value) => {
              if (hasValue(value)) {
                return /^\d{4}$/i.test(value);
              }
              return true;
            },
            message: <CustomFormatMessage id="form.address.error-format" />,
          },
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.field.zipCode-is-required" />
            ),
          },
        ],
        properties: {
          colectiveValidate: true,
          maxLength: 4,
          oneWordInput: true,
        },
      },
      MX: {
        validators: [
          {
            validator: (value) => {
              if (hasValue(value)) {
                return /^\d{5}$/i.test(value);
              }
              return true;
            },
            message: <CustomFormatMessage id="form.address.error-format" />,
          },
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.field.zipCode-is-required" />
            ),
          },
        ],
        properties: {
          colectiveValidate: true,
          maxLength: 5,
          oneWordInput: true,
        },
      },
      IT: {
        validators: [
          {
            validator: (value) => {
              if (hasValue(value)) {
                return /^\d{5}$/i.test(value);
              }
              return true;
            },
            message: <CustomFormatMessage id="form.address.error-format" />,
          },
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.field.zipCode-is-required" />
            ),
          },
        ],
        properties: {
          colectiveValidate: true,
          maxLength: 5,
          oneWordInput: true,
        },
      },
      VA: {
        validators: [
          {
            validator: (value) => {
              if (hasValue(value)) {
                return /^\d{5}$/i.test(value);
              }
              return true;
            },
            message: <CustomFormatMessage id="form.address.error-format" />,
          },
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.field.zipCode-is-required" />
            ),
          },
        ],
        properties: {
          colectiveValidate: true,
          maxLength: 5,
          oneWordInput: true,
        },
      },
      SM: {
        validators: [
          {
            validator: (value) => {
              if (hasValue(value)) {
                return /^\d{5}$/i.test(value);
              }
              return true;
            },
            message: <CustomFormatMessage id="form.address.error-format" />,
          },
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.field.zipCode-is-required" />
            ),
          },
        ],
        properties: {
          colectiveValidate: true,
          maxLength: 5,
          oneWordInput: true,
        },
      },
      BR: {
        validators: [
          {
            validator: (value) => {
              if (hasValue(value)) {
                return /^\d{1,10}$/i.test(value);
              }
              return true;
            },
            message: <CustomFormatMessage id="form.address.error-format" />,
          },
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.field.zipCode-is-required" />
            ),
          },
        ],
        properties: {
          colectiveValidate: true,
          maxLength: 10,
          oneWordInput: true,
        },
      },
      PT: {
        validators: [
          {
            validator: (value) => {
              if (hasValue(value)) {
                return /^\d{4}-\d{3}$/.test(value);
              }
              return true;
            },
            message: <CustomFormatMessage id="form.address.error-format" />,
          },
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.field.zipCode-is-required" />
            ),
          },
        ],
        properties: {
          colectiveValidate: true,
          maxLength: 8,
          oneWordInput: true,
        },
      },
      DE: {
        validators: [
          {
            validator: (value) => {
              if (hasValue(value)) {
                return /^\d{5}$/.test(value);
              }
              return true;
            },
            message: <CustomFormatMessage id="form.address.error-format" />,
          },
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.field.zipCode-is-required" />
            ),
          },
        ],
        properties: {
          maxLength: 5,
          colectiveValidate: true,
          oneWordInput: true,
        },
      },
      AT: {
        validators: [
          {
            validator: (value) => {
              if (hasValue(value)) {
                return /^\d{4}$/.test(value);
              }
              return true;
            },
            message: <CustomFormatMessage id="form.address.error-format" />,
          },
          {
            validator: (value) => hasValue(value),
            message: (
              <CustomFormatMessage id="form.field.zipCode-is-required" />
            ),
          },
        ],
        properties: {
          maxLength: 4,
          colectiveValidate: true,
          oneWordInput: true,
        },
      },
    },
  },
  town: {
    inputClass: 'text',
    label: 'form.field.town',
    platform: {
      PE: {
        validators: [
          {
            validator: (value) => hasValue(value),
            message: <CustomFormatMessage id="form.field.town.is-required" />,
          },
          {
            validator: (value) => !hasSpecialCharacters(value),
            message: <CustomFormatMessage id="form.message.incorrect-format" />,
          },
        ],
        properties: {
          maxLength: 30,
          errorMessage: (
            <CustomFormatMessage id="register.form.town.is-required" />
          ),
        },
      },
    },
    validators: [
      {
        validator: (value) => hasValue(value),
        message: <CustomFormatMessage id="form.field.town.is-required" />,
      },
      {
        validator: (value) => !hasSpecialCharacters(value),
        message: <CustomFormatMessage id="form.message.incorrect-format" />,
      },
    ],
    properties: {
      colectiveValidate: true,
      maxLength: 30,
    },
  },
  state: {
    inputClass: 'select',
    label: 'form.field.state',
    properties: {
      required: true,
      defaultValue: '',
      errorMessage: (
        <CustomFormatMessage id="register.form.state.is-required" />
      ),
      colectiveValidate: true,
    },
  },
  country: {
    inputClass: 'select',
    label: 'form.field.country',
    properties: {
      icon: 'world',
      colectiveValidate: true,
    },
  },
  telephone: {
    inputClass: 'text',
    label: 'form.address.phone',
    ...getPhonePlatformsValidatorsAndPropertiesDefault(),
    platform: getPhonePlatformsValidatorsAndProperties(),
  },
  odontology_name: {
    inputClass: 'text',
    label: 'fold.field.odontology-data.name-surname',
    validators: [
      {
        validator: (value) => hasValue(value),
        message: (
          <CustomFormatMessage id="fold.field.odontology-data.name-surname.is-required" />
        ),
      },
      {
        validator: (value) => /^[^\d*/;#|€$"'~{}[\]]*$/.test(value),
        message: <CustomFormatMessage id="form.message.name.invalid-format" />,
      },
    ],
    properties: {
      maxLength: 50,
      required: true,
      defaultValue: null,
      errorMessage: (
        <CustomFormatMessage id="fold.field.odontology-data.name-surname.is-required" />
      ),
      colectiveValidate: true,
    },
  },
  odontology_collegiate_number: {
    inputClass: 'text',
    label: 'fold.field.odontology-data.collegiate-number',
    platform: {
      ES: {
        validators: [
          {
            validator: (value) => {
              if (hasValue(value)) {
                return /^\d+$/.test(value) && value.length >= 8;
              }
              return true;
            },
            message: (
              <CustomFormatMessage id="fold.field.odontology-data.collegiate-number.format" />
            ),
          },
        ],
        properties: {
          maxLength: 8,
          required: true,
          defaultValue: null,
          errorMessage: (
            <CustomFormatMessage id="fold.field.odontology-data.name-surname.is-required" />
          ),
          colectiveValidate: true,
        },
      },
    },
    validators: [
      {
        validator: (value) => {
          if (hasValue(value)) {
            return /^\d+$/.test(value) && value.length >= 8;
          }
          return true;
        },
        message: (
          <CustomFormatMessage id="fold.field.odontology-data.collegiate-number.format" />
        ),
      },
    ],
    properties: {
      maxLength: 20,
      required: true,
      defaultValue: null,
      errorMessage: (
        <CustomFormatMessage id="fold.field.odontology-data.name-surname.is-required" />
      ),
      colectiveValidate: true,
    },
  },
  medical_center: {
    inputClass: 'select',
    label: 'form.field.medical_center',
    properties: {
      required: true,
      defaultValue: '',
      errorMessage: (
        <CustomFormatMessage id="register.form.medical-center.is-required" />
      ),
      colectiveValidate: true,
    },
  },
  plate_type: {
    inputClass: 'select',
    label: 'form.field.plate_type',
    properties: {
      required: true,
      errorMessage: (
        <CustomFormatMessage id="register.form.specialty.is-required" />
      ),
    },
  },
  plate_number: {
    inputClass: 'text',
    label: 'form.field.plate_number',
    validators: [
      {
        validator: (value) => hasValue(value),
        message: (
          <CustomFormatMessage id="form.field.plate_number-is-required" />
        ),
      },
      {
        validator: (value) => {
          if (hasValue(value) && value.length >= 3 && value.length <= 10) {
            return /^\d[0-9/-]{1,8}\d$/.test(value);
          }
          return false;
        },
        message: <CustomFormatMessage id="form.plate_number.error-format" />,
      },
    ],
    properties: {
      maxLength: 10,
    },
  },
};
