import {
  ModalBehaviourProps,
  YuButton,
  YuFlex,
  YuInputText,
  YuModal,
  YuModalContent,
  YuModalFooter,
  YuParagraph,
  YuSpacer,
  YuTitle,
} from '@isdin/yuma-react-web-pin';
import { FormattedMessage, useIntl } from 'react-intl';
import { validatePlatformDNI } from '../../../validations/documentId.validator';
import { yuFeedback } from '../../../helpers/yuFeedback';
import { getConfig } from './dniMissing.form.config';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { config as configApp } from '../../../../config/config';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { updateUserDocumentId } from '../../../actions/authActions';
import { connect } from 'react-redux';
import { IRootReducers } from '../../../reducers';

const { PLATFORM } = configApp.APP;

interface editDNIInterface {
  documentId: string;
}

const behaviour: ModalBehaviourProps = {
  close: {
    /** Close when user click into content. */
    onClickClose: true,
    /** Close when user click out of the popper area. */
    onClickOutArea: false,
    onKeyDown: [],
  },
};

export const _EditDNIModal = ({
  dniModalIsOpen,
  closeModalCompleteDni,
  handleSubmit,
  user,
  updateUserDocumentId,
}): JSX.Element => {
  const { userDocumentID } = user.salesforce || {};
  const { formatMessage } = useIntl();

  const form = useForm<editDNIInterface>({
    mode: 'onChange',
    defaultValues: {
      documentId: userDocumentID as string,
    },
  });

  /**
   * updates documentId on user Redux object after form is valid
   *
   * @param data data object to update
   */
  function onSubmit(data: editDNIInterface) {
    updateUserDocumentId(data.documentId);
    handleSubmit(data);
  }

  return (
    <YuModal
      isOpen={dniModalIsOpen}
      closable={true}
      behaviour={behaviour}
      size="M"
      onClose={closeModalCompleteDni}
    >
      <YuTitle size="S">
        <FormattedMessage id="page.redeem-points.checkout.personal-data.dni.modal.complete.title" />
      </YuTitle>
      <YuSpacer size="XS" />
      <YuParagraph size="M">
        <FormattedMessage id="page.redeem-points.checkout.personal-data.dni.modal.complete.sub-title" />
      </YuParagraph>
      <YuSpacer size="XS" />
      <YuModalContent className="dni-form-content">
        <Controller
          control={form.control}
          name="documentId"
          rules={{
            required: formatMessage({
              id: 'form.message.user-document.invalid-format',
            }),
            validate: (value: string) => {
              if (!validatePlatformDNI(value)) {
                return formatMessage({
                  id: 'form.message.user-document.invalid-format',
                });
              }
              return true;
            },
          }}
          render={({ field: { onChange, value }, fieldState }) => (
            <>
              <YuInputText
                name="document_id"
                value={value}
                onChange={(event) => {
                  onChange(event.currentTarget.value);
                }}
                label={formatMessage({
                  id: 'page.redeem-points.checkout.personal-data.modal.form.dni-placeholder',
                })}
                feedback={yuFeedback(fieldState.error?.message, 'error')}
                maxLength={
                  getConfig('document_id', PLATFORM).properties?.maxLength
                }
              />
            </>
          )}
        />
      </YuModalContent>
      <YuModalFooter>
        <YuFlex flexDirection="column">
          <YuButton
            size="M"
            disabled={!form.formState.isValid}
            variant="primary"
            onClick={form.handleSubmit(onSubmit)}
          >
            <FormattedMessage id="page.redeem-points.checkout.personal-data.dni.modal.complete.button" />
          </YuButton>
        </YuFlex>
      </YuModalFooter>
    </YuModal>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    user: state.auth.user,
  };
};
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ updateUserDocumentId }, dispatch);

export const EditDNIModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(_EditDNIModal);
