import {
  useSize,
  YuFlex,
  YuSkeleton,
  YuSkeletonWrapper,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import { NextPage } from 'next';
import { YuContainer } from '../../layout/YuContainer';
import { ChallengeListCards } from '../ChallengeListCards';

const SkeletonTalks: NextPage = (): JSX.Element => {
  const { upXS } = useSize();

  return (
    <>
      <YuContainer>
        <YuSkeletonWrapper width="100%" className="yuma-custom-skeleton">
          <YuFlex width="100%" flexDirection="column">
            <YuFlex
              flexDirection="column"
              ml={{ onlyXS: 'S', upXS: 'S' }}
              mr={{ onlyXS: 'S', upXS: 'S' }}
            >
              <YuSpacer size="L" />
              <YuSkeleton
                height={40}
                variant="text"
                width={upXS ? '292px' : '100%'}
              />
              <YuSpacer size="L" />
            </YuFlex>

            <ChallengeListCards />

            <YuSpacer size="L" />

            <YuFlex justify="center">
              <YuSkeleton variant="text" width={118} height={32} />
            </YuFlex>

            <YuSpacer size="XL" />
          </YuFlex>
        </YuSkeletonWrapper>
      </YuContainer>
    </>
  );
};

export default SkeletonTalks;
