import {
  useSize,
  YuFlex,
  YuGrid,
  YuSkeleton,
  YuSkeletonWrapper,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import _ from 'lodash';
import { NextPage } from 'next';
import { YuContainer } from '../../layout/YuContainer';

const SkeletonRedeemPoints: NextPage = (): JSX.Element => {
  const { upXS, upL, upM, upS, upXL } = useSize();
  const CARDS_NUMBER_DESKTOP = 15;
  const CARD_NUMBER_TABLET = 8;
  const CARD_NUMBER_MOBILE = 9;

  const CARD_NUMBER = upS
    ? CARDS_NUMBER_DESKTOP
    : upXS
    ? CARD_NUMBER_TABLET
    : CARD_NUMBER_MOBILE;
  const skeletonCard = () => {
    return (
      <YuFlex flexDirection="column" width={'100%'} style={{ margin: 'auto' }}>
        <YuSkeleton
          variant="rectangular"
          width="100%"
          height={{ initial: 200, upXS: 420 }}
        />
        <YuFlex flexDirection="column" align={'center'}>
          <YuSpacer size={upM ? 'M' : 'L'} />
          <YuSkeleton variant="text" height={24} width="100%" />
          <YuSpacer size="S" />
          <YuSkeleton variant="text" height={22} width="80%" />
          <YuSpacer size="XS" />
          <YuSkeleton variant="text" height={25} width="120px" />
          <YuSpacer size="S" />
          <YuSkeleton variant="text" height={28} width="130px" />
          <YuSpacer size={upM ? 'M' : 'L'} />
        </YuFlex>
      </YuFlex>
    );
  };

  const headerGridDesktop = () => {
    return (
      <YuContainer>
        <YuFlex
          flexDirection="column"
          style={{ margin: 'auto' }}
          width={'100%'}
          pl={{ onlyXS: 'S', upXS: 'S' }}
          pr={{ onlyXS: 'S', upXS: 'S' }}
        >
          <YuSpacer size="L" />
          <YuSkeleton
            height={40}
            variant="text"
            width={upXS ? '292px' : '100%'}
          />
          <YuSpacer size="M" />
          <YuFlex justify="end">
            <YuSkeleton height={31} variant="text" width={'230px'} />
          </YuFlex>
          <YuSpacer size="M" />
          <YuFlex style={{ paddingLeft: '8px' }}>
            <YuSkeleton height={40} variant="text" width={'150px'} />
          </YuFlex>
          <YuSpacer size="S" />
          <YuFlex justify="end" style={{ paddingRight: '50px' }}>
            <YuSkeleton height={24} variant="text" width={'220px'} />
          </YuFlex>
          <YuSpacer size="L" />
        </YuFlex>
      </YuContainer>
    );
  };

  const headerGridMobile = () => {
    return (
      <YuContainer>
        <YuFlex
          flexDirection="column"
          style={{ margin: 'auto' }}
          width={'100%'}
          pl={{ onlyXS: 'S', upXS: 'S' }}
          pr={{ onlyXS: 'S', upXS: 'S' }}
        >
          <YuSpacer size="XL" />
          <YuSkeleton height={40} variant="text" width={'80%'} />
          <YuSpacer size="XL" />
          <YuFlex justify="end">
            <YuSkeleton height={31} variant="text" width={'100%'} />
          </YuFlex>
          <YuSpacer size="M" />
          <YuFlex justify="end">
            <YuSkeleton height={40} variant="text" width={'40px'} />
          </YuFlex>
          <YuSpacer size="L" />
          <YuFlex justify="end" style={{ paddingRight: '50px' }}>
            <YuSkeleton height={24} variant="text" width={'220px'} />
          </YuFlex>
          <YuSpacer size="L" />
        </YuFlex>
      </YuContainer>
    );
  };

  /**
   * returns the repeat grid string depending on the layout size
   *
   * @returns string
   */
  function getRepeatString(): string {
    if (upXL) return 'repeat(5, minmax(0px, 1fr))';
    if (upL) return 'repeat(4, minmax(0px, 1fr))';
    if (upXS) return 'repeat(2, minmax(0px, 1fr))';
    return 'repeat(2, minmax(0px, 50%))';
  }

  return (
    <>
      <YuContainer>
        <YuSkeletonWrapper width={'100%'} className="yuma-custom-skeleton">
          <YuFlex width={'100%'} flexDirection="column">
            {upXS ? headerGridDesktop() : headerGridMobile()}
            <YuGrid
              gridTemplateColumns={getRepeatString()}
              gridGap={upS ? 'M' : 'XS'}
            >
              {_.times(CARD_NUMBER, () => skeletonCard())}
            </YuGrid>
            <YuSpacer size="M" />
            <YuFlex justify={'center'}>
              <YuSkeleton height={36} variant="text" width={'133px'} />
            </YuFlex>
            <YuSpacer size="XL" />
          </YuFlex>
        </YuSkeletonWrapper>
      </YuContainer>
    </>
  );
};

export default SkeletonRedeemPoints;
