import { CustomFormatMessage } from '../../utils';
import { getPhoneMinLengthRegex } from './getPhoneMinLengthRegex';
import { hasValue } from './helpers';

export const getPhonePlatformsValidatorsAndProperties = (
  country: string | null = null,
  formatMessage: ({ id: string }) => string = null
) => {
  const phonePlatformsValidators = {
    US: {
      validators: [
        {
          validator: (value) => hasValue(value),
          message: formatMessage ? (
            formatMessage({ id: 'form.field.telephone-is-required' })
          ) : (
            <CustomFormatMessage id="form.field.telephone-is-required" />
          ),
        },
        {
          validator: (value) => {
            if (hasValue(value)) {
              return getPhoneMinLengthRegex('US').test(value);
            }
            return true;
          },
          message: formatMessage ? (
            formatMessage({ id: 'form.field.telephone.format' })
          ) : (
            <CustomFormatMessage id="form.field.telephone.format" />
          ),
        },
      ],
      properties: {
        maxLength: 15,
        colectiveValidate: true,
      },
    },
    ES: {
      validators: [
        {
          validator: (value) => hasValue(value),
          message: formatMessage ? (
            formatMessage({ id: 'form.field.telephone-is-required' })
          ) : (
            <CustomFormatMessage id="form.field.telephone-is-required" />
          ),
        },
        {
          validator: (value) => {
            if (hasValue(value)) {
              return getPhoneMinLengthRegex('ES').test(value);
            }
            return true;
          },
          message: formatMessage ? (
            formatMessage({ id: 'form.field.telephone.format' })
          ) : (
            <CustomFormatMessage id="form.field.telephone.format" />
          ),
        },
      ],
      properties: {
        maxLength: 15,
        colectiveValidate: true,
      },
    },
    AD: {
      validators: [
        {
          validator: (value) => hasValue(value),
          message: formatMessage ? (
            formatMessage({ id: 'form.field.telephone-is-required' })
          ) : (
            <CustomFormatMessage id="form.field.telephone-is-required" />
          ),
        },
        {
          validator: (value) => {
            if (hasValue(value)) {
              return getPhoneMinLengthRegex('AD').test(value);
            }
            return true;
          },
          message: formatMessage ? (
            formatMessage({ id: 'form.field.telephone.format' })
          ) : (
            <CustomFormatMessage id="form.field.telephone.format" />
          ),
        },
      ],
      properties: {
        maxLength: 15,
        colectiveValidate: true,
      },
    },
    AR: {
      validators: [
        {
          validator: (value) => hasValue(value),
          message: formatMessage ? (
            formatMessage({ id: 'form.field.telephone-is-required' })
          ) : (
            <CustomFormatMessage id="form.field.telephone-is-required" />
          ),
        },
        {
          validator: (value) => {
            if (hasValue(value)) {
              return getPhoneMinLengthRegex('AR').test(value);
            }
            return true;
          },
          message: formatMessage ? (
            formatMessage({ id: 'form.field.telephone.format' })
          ) : (
            <CustomFormatMessage id="form.field.telephone.format" />
          ),
        },
      ],
      properties: {
        maxLength: 15,
        colectiveValidate: true,
      },
    },
    IT: {
      validators: [
        {
          validator: (value) => hasValue(value),
          message: formatMessage ? (
            formatMessage({ id: 'form.field.telephone-is-required' })
          ) : (
            <CustomFormatMessage id="form.field.telephone-is-required" />
          ),
        },
        {
          validator: (value) => {
            if (hasValue(value)) {
              return getPhoneMinLengthRegex('IT').test(value);
            }
            return true;
          },
          message: formatMessage ? (
            formatMessage({ id: 'form.field.telephone.format' })
          ) : (
            <CustomFormatMessage id="form.field.telephone.format" />
          ),
        },
      ],
      properties: {
        maxLength: 15,
        colectiveValidate: true,
      },
    },
    SM: {
      validators: [
        {
          validator: (value) => hasValue(value),
          message: formatMessage ? (
            formatMessage({ id: 'form.field.telephone-is-required' })
          ) : (
            <CustomFormatMessage id="form.field.telephone-is-required" />
          ),
        },
        {
          validator: (value) => {
            if (hasValue(value)) {
              return getPhoneMinLengthRegex('SM').test(value);
            }
            return true;
          },
          message: formatMessage ? (
            formatMessage({ id: 'form.field.telephone.format' })
          ) : (
            <CustomFormatMessage id="form.field.telephone.format" />
          ),
        },
      ],
      properties: {
        maxLength: 15,
        colectiveValidate: true,
      },
    },
    VA: {
      validators: [
        {
          validator: (value) => hasValue(value),
          message: formatMessage ? (
            formatMessage({ id: 'form.field.telephone-is-required' })
          ) : (
            <CustomFormatMessage id="form.field.telephone-is-required" />
          ),
        },
        {
          validator: (value) => {
            if (hasValue(value)) {
              return getPhoneMinLengthRegex('VA').test(value);
            }
            return true;
          },
          message: formatMessage ? (
            formatMessage({ id: 'form.field.telephone.format' })
          ) : (
            <CustomFormatMessage id="form.field.telephone.format" />
          ),
        },
      ],
      properties: {
        maxLength: 15,
        colectiveValidate: true,
      },
    },
    DE: {
      validators: [
        {
          validator: (value) => hasValue(value),
          message: formatMessage ? (
            formatMessage({ id: 'form.field.telephone-is-required' })
          ) : (
            <CustomFormatMessage id="form.field.telephone-is-required" />
          ),
        },
        {
          validator: (value) => {
            if (hasValue(value)) {
              return getPhoneMinLengthRegex('DE').test(value);
            }
            return true;
          },
          message: formatMessage ? (
            formatMessage({ id: 'form.field.telephone.format' })
          ) : (
            <CustomFormatMessage id="form.field.telephone.format" />
          ),
        },
      ],
      properties: {
        maxLength: 15,
        colectiveValidate: true,
      },
    },
    AT: {
      validators: [
        {
          validator: (value) => hasValue(value),
          message: formatMessage ? (
            formatMessage({ id: 'form.field.telephone-is-required' })
          ) : (
            <CustomFormatMessage id="form.field.telephone-is-required" />
          ),
        },
        {
          validator: (value) => {
            if (hasValue(value)) {
              return getPhoneMinLengthRegex('AT').test(value);
            }
            return true;
          },
          message: formatMessage ? (
            formatMessage({ id: 'form.field.telephone.format' })
          ) : (
            <CustomFormatMessage id="form.field.telephone.format" />
          ),
        },
      ],
      properties: {
        maxLength: 15,
        colectiveValidate: true,
      },
    },
  };
  if (country) {
    if (phonePlatformsValidators[country]) {
      return phonePlatformsValidators[country];
    }
    return getPhonePlatformsValidatorsAndPropertiesDefault(formatMessage);
  }
  return phonePlatformsValidators;
};

export const getPhonePlatformsValidatorsAndPropertiesDefault = (
  formatMessage: ({ id: string }) => string = null
) => {
  return {
    validators: [
      {
        validator: (value) => hasValue(value),
        message: formatMessage ? (
          formatMessage({ id: 'form.field.telephone-is-required' })
        ) : (
          <CustomFormatMessage id="form.field.telephone-is-required" />
        ),
      },
      {
        validator: (value) => {
          if (hasValue(value)) {
            return getPhoneMinLengthRegex(null).test(value);
          }
          return true;
        },
        message: formatMessage ? (
          formatMessage({ id: 'form.field.telephone.format' })
        ) : (
          <CustomFormatMessage id="form.field.telephone.format" />
        ),
      },
    ],
    properties: {
      maxLength: 15,
      colectiveValidate: true,
    },
  };
};
