import { Layout } from 'antd';
import { NextPage } from 'next';
import React from 'react';
import ChatCustomerCare from 'src/components/chatCustomerCare/ChatCustomerCare';
import BasicFooter_Copyright from 'src/components/layout/BasicFooter_Copyright';
import BasicPreFooter from 'src/components/layout/BasicPreFooter';
import getConfig from 'next/config';
import ConditionalRenderer from '../ConditionalRenderer';
import ChatCustomerCarev2 from '../chatCustomerCarev2/ChatCustomerCare';

const { APP_ENV } = getConfig().publicRuntimeConfig;

interface OwnProps {
  showFooter: boolean;
}

const BasicFooter: NextPage<OwnProps> = ({ showFooter }) => {
  return (
    <Layout.Footer className={`basicFooter`}>
      {showFooter && <BasicPreFooter />}
      <BasicFooter_Copyright />
      <ConditionalRenderer
        condition={APP_ENV === 'production'}
        trueComponent={<ChatCustomerCare />}
        falseComponent={<ChatCustomerCarev2 />} // !!! NO HABILITAR EN PROD HASTA CONFIRMACIÓN DEL EQUIPO DE SALESFORCE
      />
    </Layout.Footer>
  );
};

export default BasicFooter;
