import { Button, Col, Modal, Row } from 'antd';
import { config } from 'config/config';
import routePaths from 'config/routes';
import { NextPage } from 'next';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { getDataCall } from 'services/api';
import apiPaths from 'services/apiPaths';
import { privateRoute } from 'services/privateRoute';
import {
  fetchCampaings,
  insertAnnualCampaign,
  insertChallenges,
  updateChallenges,
  updateFilters,
} from 'src/actions/appActions';
import { EmptyList } from 'src/components';
import SubHeader from 'src/components/SubHeader';
import Link from 'src/components/Link';
import BuildPageChallenge, {
  showMoreButton,
} from 'src/components/challenge/ChallengeBuildPage';
import ChallengeCard, {
  renderCampaignDetail,
} from 'src/components/challenge/ChallengeCard';
import { builderChallenge } from 'src/components/challenge/builder/BuilderChallenge';
import DistributorFilter from 'src/components/distributorElements/filter/DistributorFilter';
import { IRootReducers } from 'src/reducers';
import {
  ChallengedTabsEnum,
  ChallengeTypeEnum,
  ResourceTypeEnum,
} from 'src/shared/enums';
import {
  ChallengeCardButton,
  ChallengeDetail,
  IChallenge,
  IChallengesList,
  IFilter,
  ILinkedChallenges,
  IResponseChallengesList,
} from 'src/shared/models';
import { ReduxPageContext } from 'src/store';
import {
  fetchInitialData,
  getTranslatedData,
  prepareChallengeFiltersResult,
  renderChallengeCard,
} from '../../../utils';

import { fetchFilters } from '../../adapters';
import TypeformNPS from 'src/components/typeforms/nps/TypeFormNPS';
import { DateTime } from 'luxon';
import SkeletonCampaignCards from '../../components/skeletonComponents/Pages/CampaignCards.skeleton';
import { YuContainer } from '../../components/layout/YuContainer';

const { GET_CAMPAIGNS } = apiPaths.CHALLENGES;
const PAGE_SIZE = 3;

interface OwnProps {
  children?: React.ReactNode;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  OwnProps;

export const renderChallengeCampaign = (
  challenge: ChallengeDetail
): JSX.Element => {
  const { formatMessage } = useIntl();

  let userStatus: { idResource: number; status: number };
  const challengeCardButton: ChallengeCardButton = {
    className: 'challengeCard__link challengeCard__link--active',
    path: routePaths.PAGES.CAMPAIGN_DETAIL,
    title: formatMessage({ id: 'campaign.see' }),
  };

  const config: ChallengeDetail = {
    ...challenge,
    challengeName: getTranslatedData(challenge, 'name'),
    challengeDescription:
      getTranslatedData(challenge, 'description') ||
      getTranslatedData(challenge, 'descriptionLong'),
    challengeCardButton,
    userStatus,
  };

  return <ChallengeCard {...config} />;
};

const CampaignsPage: NextPage<Props> = ({
  campaignsList,
  totalChallenges,
  annualCampaignData,
  shortDateFormat,
  updateChallenges,
  fetchCampaings,
  savedFilters,
  user,
}): JSX.Element => {
  const { locale, formatNumber } = useIntl();

  const getCurrentListPage = () => Math.floor(campaignsList.length / PAGE_SIZE);

  const [annualCampaign, setAnnualCampaign] =
    useState<ChallengeDetail>(annualCampaignData);
  const [campaigns, setCampaigns] = useState<ChallengeDetail[]>();
  const [filters, setFilters] = useState<ChallengedTabsEnum>(
    ChallengedTabsEnum.ALL
  );
  const [canShowMore, setCanShowMore] = useState<boolean>(
    totalChallenges > campaignsList.length
  );
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [requestPage, setRequestPage] = useState<number>(getCurrentListPage);
  const [displayFilters, setDisplayFilters] = useState<IFilter[]>(savedFilters);
  const [selectedFilters, setSelectedFilters] = useState<string>(
    prepareChallengeFiltersResult(savedFilters)
  );
  const [showSeeMore, setShowSeeMore] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleShowMore = async () => {
    setIsLoadingMore(true);

    const _categories = '';

    const request = `${GET_CAMPAIGNS}?page=${requestPage}&size=${PAGE_SIZE}${_categories}${selectedFilters}`;

    // TODO: Estas líneas se repiten en las otras challenge-lists. Centralizar en challenge/utils.
    try {
      const response: { data: IResponseChallengesList } = await getDataCall({
        dataPath: request,
        callConfig: {},
      });

      const ids = new Set(campaigns.map((campaign) => campaign.challengeId));
      const newchallengeAcc: ChallengeDetail[] = [
        ...campaignsList,
        ...response.data.challenges
          .filter((campaign: IChallenge) => !ids.has(campaign.idChallenge))
          .map((campaign: IChallenge) => builderChallenge(campaign, user)),
      ];

      updateChallenges({
        challengeType: 'campaigns',
        challengeList: {
          total: response.data.total,
          challenges: newchallengeAcc,
        },
      });

      setRequestPage(requestPage + 1);
      const challengesLeft = totalChallenges - campaignsList.length;
      setCanShowMore(challengesLeft > response.data.challenges.length);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const renderCampaigns = (): JSX.Element | JSX.Element[] => {
    let filteredCampaigns: ChallengeDetail[] = [];
    if (!campaigns?.length) return <EmptyList page_name="campaigns" />;
    switch (filters) {
      case ChallengedTabsEnum.PENDING:
        filteredCampaigns = campaigns.filter(
          ({ isChallengeCompleted, isCampaignActive, hasCompletedResource }) =>
            !isChallengeCompleted && (isCampaignActive || hasCompletedResource)
        );
        break;
      case ChallengedTabsEnum.COMPLETED:
        filteredCampaigns = campaigns.filter(
          ({ isChallengeCompleted }) => isChallengeCompleted
        );
        break;
      case ChallengedTabsEnum.FAVORITES:
        filteredCampaigns = campaigns.filter(
          ({ challengeLikes: { liked } }) => liked
        );
        break;
      default:
        filteredCampaigns = campaigns;
        break;
    }

    if (!filteredCampaigns.length) return <EmptyList page_name="campaigns" />;

    return filteredCampaigns.map((challenge: ChallengeDetail) =>
      renderChallengeCard({ challenge })
    );
  };

  useEffect(() => {
    if (!annualCampaignData) return;

    const _annualCampaign = {
      ...annualCampaign,
      challengeName: getTranslatedData(annualCampaignData, 'name', locale),
      challengeDescription:
        getTranslatedData(annualCampaignData, 'descriptionLong', locale) ||
        getTranslatedData(annualCampaignData, 'description', locale),
      challengeListPath: {
        path: routePaths.PAGES.CAMPAIGN,
        title: 'app.campaigns',
      },
    };
    setAnnualCampaign(_annualCampaign);
  }, [annualCampaignData]);

  useEffect(() => {
    if (!annualCampaign) return;
    if (!annualCampaign.descriptionLong?.trim()) return;

    if (annualCampaign.descriptionLong?.length >= 260) {
      setShowSeeMore(true);
    }
  }, [annualCampaign?.challengeDescription]);

  useEffect(() => {
    setCampaigns(campaignsList);
    setCanShowMore(totalChallenges > campaignsList.length);
  }, [campaignsList]);

  const handleFilter = (filters: IFilter[]) => {
    setSelectedFilters(prepareChallengeFiltersResult(filters));
    setRequestPage(2);

    fetchCampaings(user, filters, campaignsList.length);
  };

  useEffect(() => {
    setDisplayFilters(savedFilters);
  }, [savedFilters.length]);

  useEffect(() => {
    fetchCampaings(user, [], campaignsList.length);

    const activeFilters = displayFilters.filter(({ items }) =>
      items.some((item) => item.isChecked)
    );

    if (activeFilters.length) {
      handleFilter(activeFilters);
    }
  }, []);

  const FiltersComponent = (): JSX.Element => {
    return <DistributorFilter filters={displayFilters} onDone={handleFilter} />;
  };

  const showMoreComponent =
    filters == ChallengedTabsEnum.ALL
      ? showMoreButton(
          `page.marketing-materials.show-more`,
          isLoadingMore,
          canShowMore,
          handleShowMore,
          <SkeletonCampaignCards pt="XS" />
        )
      : null;

  const ViewMoreButton = () => (
    <span
      className="challengeDetail__view-more"
      onClick={() => setShowModal(true)}
    >
      <FormattedMessage id="page.campaign.see-detail-button" />
    </span>
  );
  const CustomCloseIcon = () => {
    return (
      <Row className="challengeDetail__modal__close-icon">
        <i className={`icon icon--close`} />
      </Row>
    );
  };

  const AnnualCampaignComponent = (): JSX.Element => {
    if (!annualCampaign) return null;

    const numberOfChapters =
      (
        annualCampaign.challengeResources.find(
          (resource) => resource.type === ResourceTypeEnum.LINKED_CHALLENGES
        )?.config as ILinkedChallenges
      )?.challenges.length || 0;

    return (
      <div className="annual-campaign">
        <Row
          justify="center"
          align="middle"
          className="annual-campaign__static-text"
        >
          <h3 className="h3">
            <FormattedMessage id="page.campaign.static-text-one.title" />
          </h3>
          <p className="description big-body">
            <FormattedMessage id="page.campaign.static-text-one.subtitle" />
          </p>
        </Row>
        <div className="annual-campaign__content">
          <Row gutter={35}>
            <Col span={24} lg={15} className="annual-campaign__banner">
              <YuContainer>
                <Row
                  className="annual-campaign-detail--container-banner"
                  justify="center"
                >
                  <img
                    className="show_only_desktop_excluded"
                    loading="lazy"
                    alt={`annual-campaign-desktop--${annualCampaign.challengeId}`}
                    src={annualCampaign.cardImage ?? config.APP.DEFAULT_IMAGE}
                  />
                  <img
                    className="not_show_on_desktop"
                    loading="lazy"
                    alt={`annual-campaign-mobile--${annualCampaign.challengeId}`}
                    src={
                      annualCampaign.cardImageMobile ?? config.APP.DEFAULT_IMAGE
                    }
                  />
                </Row>
              </YuContainer>
            </Col>

            <Col span={24} lg={9} className="annual-campaign__info">
              <h4 className="h4">{annualCampaign.name}</h4>
              <div
                className="annual-campaign__info__description"
                dangerouslySetInnerHTML={{
                  __html: annualCampaign.challengeDescription,
                }}
              />
              {showSeeMore && <ViewMoreButton />}
              <Modal
                visible={showModal}
                footer={null}
                onCancel={() => setShowModal(false)}
                className="challengeDetail__collapse-modal"
                closeIcon={<CustomCloseIcon />}
              >
                <Row
                  justify="start"
                  align="middle"
                  className="challengeDetail__title__text"
                  data-id="challengeDetails"
                >
                  {getTranslatedData(config, 'challengeName') ||
                    getTranslatedData(config, 'name')}
                </Row>
                <div
                  className="challengeDetail__description--extended"
                  dangerouslySetInnerHTML={{
                    __html: annualCampaign.challengeDescription,
                  }}
                />
              </Modal>
              <div className="details-container">
                {renderCampaignDetail(
                  'menu',
                  'page.challenge-card.{chapters}-chapters',
                  {
                    chapters: numberOfChapters,
                  }
                )}
                {renderCampaignDetail(
                  'calendar',
                  'page.challenge-card.{startDate}-{endDate}',
                  {
                    startDate: DateTime.fromJSDate(
                      new Date(annualCampaign.challengeDates?.startDate)
                    ).toFormat(shortDateFormat),
                    endDate: DateTime.fromJSDate(
                      new Date(annualCampaign.challengeDates?.endDate)
                    ).toFormat(shortDateFormat),
                  }
                )}
                {renderCampaignDetail(
                  'puntos',
                  'page.challenge.plus-sign-{points}-points',
                  {
                    points: formatNumber(annualCampaign.challengePoints),
                  },
                  true
                )}
              </div>
              <Link
                href={{
                  pathname: routePaths.PAGES.ANNUAL_CAMPAIGN,
                }}
              >
                <Button className="btn-primary btn-primary--black">
                  <FormattedMessage id="page.campaign.see-detail-button" />
                </Button>
              </Link>
            </Col>
          </Row>
        </div>
        <div className="annual-campaign__static-text">
          <h3 className="h3">
            <FormattedMessage id="page.campaign.static-text-two.title" />
          </h3>
          <p className="description-bottom big-body">
            <FormattedMessage id="page.campaign.static-text-two.subtitle" />
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <SubHeader />
      <BuildPageChallenge
        pageClassName="campaignsPage"
        onChangeTab={setFilters}
        renderChallenges={renderCampaigns}
        showMoreComponent={showMoreComponent}
        filterComponent={<FiltersComponent />}
        headComponent={AnnualCampaignComponent()}
        shouldRenderTabs={campaigns?.length > 0}
        tabs={[
          { key: ChallengedTabsEnum.ALL, label: 'app.all' },
          { key: ChallengedTabsEnum.PENDING, label: 'app.pending' },
          { key: ChallengedTabsEnum.COMPLETED, label: 'app.completed' },
          { key: ChallengedTabsEnum.FAVORITES, label: 'app.my-favourites' },
        ]}
      />
      <div className="ant-row">
        <TypeformNPS user={user} />
      </div>
    </>
  );
};

/*-------------------------------------------*/
/*------------ GET INITIAL PROPS ------------*/
/*-------------------------------------------*/

const initialAction = async (
  accessToken: string,
  ctx: ReduxPageContext
): Promise<{ data: unknown }> => {
  const state: IRootReducers = ctx.store.getState();
  const isPreview = state.auth.isPreview;
  const savedCampaigns = state.app.challenges.campaigns;
  let campaigns: IChallengesList = {
    total: savedCampaigns.total,
    challenges: savedCampaigns.challenges,
    filters: savedCampaigns.filters,
    filtersLoaded: savedCampaigns.filtersLoaded,
  };

  let annualCampaign: ChallengeDetail = state.app.challenges.annualCampaign;

  try {
    // TODO:Move 6 to config/config.ts
    const areLoadedChallenges = savedCampaigns.total > 0;
    let filterList: IFilter[] = [];
    const filtersLoaded = state.app.challenges.campaigns.filtersLoaded;

    if (!areLoadedChallenges) {
      let filtersPromise: Promise<IFilter[]>;
      if (!filtersLoaded) {
        filtersPromise = fetchFilters(ChallengeTypeEnum.CAMPAIGN);
      } else {
        filtersPromise = Promise.resolve(
          state.app.challenges.trainings.filters
        );
      }

      const [response, annualCampaigns, filterListResult]: [
        IResponseChallengesList,
        IChallenge[],
        IFilter[]
      ] = await Promise.all([
        fetchInitialData(accessToken, `${GET_CAMPAIGNS}?size=6`, ctx),
        fetchInitialData(
          accessToken,
          `${apiPaths.CHALLENGES.GET_ANNUAL_CAMPAIGN}${
            isPreview ? '?isPreview=true' : ''
          }`,
          ctx
        ),
        filtersPromise,
      ]);

      filterList = filterListResult;

      annualCampaign = annualCampaigns
        ?.filter(({ status, active }: IChallenge) => status && active)
        ?.map((campaign: IChallenge) =>
          builderChallenge(campaign, state.auth.user)
        )
        ?.pop();

      const campaignsList: ChallengeDetail[] = response.challenges.map(
        (challenge) => builderChallenge(challenge, state.auth.user)
      );

      campaigns = {
        total: response.total,
        challenges: campaignsList,
        filters: savedCampaigns.filters,
        filtersLoaded: savedCampaigns.filtersLoaded,
      };
    }

    if (ctx.store.dispatch) {
      insertAnnualCampaign(annualCampaign)(ctx.store.dispatch);

      insertChallenges({
        challengeList: campaigns,
        challengeKey: 'campaigns',
      })(ctx.store.dispatch);
    }

    if (!filtersLoaded) {
      updateFilters(ctx, ChallengeTypeEnum.CAMPAIGN, filterList);
    }

    return { data: [] };
  } catch (error) {
    console.error('SERVER ERROR: ', error);
    return { data: null };
  }
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    user: state.auth.user,
    totalChallenges: state.app.challenges.campaigns.total,
    savedFilters: state.app.challenges.campaigns.filters,
    campaignsList: state.app.challenges.campaigns.challenges,
    annualCampaignData: state.app.challenges.annualCampaign,
    shortDateFormat: state.platform.shortDateFormat,
    categoryList: state.app.categoryList,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ updateChallenges, fetchCampaings }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(privateRoute({ WrappedComponent: CampaignsPage, initialAction }));
