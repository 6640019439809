import { Col, Row } from 'antd';
import { NextPage } from 'next';
import { FormattedMessage } from 'react-intl';
import { useRouter } from 'next/router';
import { usePageName } from '../../utils';
import { YuContainer } from './layout/YuContainer';

const SubHeader: NextPage<unknown> = (): JSX.Element => {
  const router = useRouter();
  const title: string | null = usePageName(router);

  return title === null ? (
    <></>
  ) : (
    <div className="content-subheader">
      <YuContainer>
        <Row justify="center">
          <Col span={24}>
            <Row className="content-subheader-title" align="middle">
              <h3 className="subheader-title h3">
                <FormattedMessage id={title} />
              </h3>
            </Row>
          </Col>
        </Row>
      </YuContainer>
    </div>
  );
};

export default SubHeader;
