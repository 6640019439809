export enum PIN_Specialty_Salesforce_Enum {
  PREVIEW = 0,
  DERMATOLOGO = 67,
  M_ESTETICA = 68,
  GINECOLOGO = 69,
  PEDIATRIA = 70,
  ASISTENCIA_PRIMARIA = 71,
  ODONTOESTOMATOLOGO = 72,
  PODOLOGO = 73,
  HIGIENISTAS = 74,
  RADIOTERAPEUTA = 75,
  COMADRONAS = 76,
  ENFERMERIA = 77,
  OTRAS = 78,
  CIRUJANO_PLASTICO = 80,
}

export enum PIN_Specialty_Enum {
  PREVIEW = 0,
  DERMATOLOGO = 14,
  M_ESTETICA = 15,
  GINECOLOGO = 16,
  PEDIATRIA = 17,
  ASISTENCIA_PRIMARIA = 18,
  ODONTOESTOMATOLOGO = 19,
  PODOLOGO = 20,
  HIGIENISTAS = 21,
  RADIOTERAPEUTA = 22,
  COMADRONAS = 23,
  ENFERMERIA = 24,
  OTRAS = 25,
  CIRUJANO_PLASTICO = 26,
}

export enum ResidentSpecialtiesEsEnum {
  DERMATOLOGO = '67',
}

export enum OdontologyInfoSpecialtiesEsEnum {
  HIGIENISTAS = '74',
}

export enum ResidentSpecialtiesArEnum {
  DERMATOLOGO = '67',
}

export enum ResidentSpecialtiesCoEnum {
  DERMATOLOGO = '67',
}

export enum ResidentSpecialtiesPeEnum {
  DERMATOLOGO = '67',
  PEDIATRA = '70',
}

export enum ResidentSpecialtiesGtEnum {
  DERMATOLOGO = '67',
}

export enum ResidentSpecialtiesClEnum {
  DERMATOLOGO = '67',
}

export enum ResidentSpecialtiesMxEnum {
  DERMATOLOGO = '67',
  M_ESTETICA = '68',
  CIRUJANO_PLASTICO = '80',
  ASISTENCIA_PRIMARIA = '71',
  ODONTOESTOMATOLOGO = '72',
}

export enum ResidentSpecialtiesPtEnum {
  DERMATOLOGO = '67',
}

export enum ResidentSpecialtiesItEnum {
  DERMATOLOGO = '67',
}

export enum ResidentSpecialtiesBrEnum {
  DERMATOLOGO = '67',
}

export enum ResidentSpecialtiesDeEnum {
  DERMATOLOGO = '67',
  GINECOLOGO = '69',
  PEDIATRA = '70',
  M_ESTETICA = '68',
  ASISTENCIA_PRIMARIA = '71',
}

export enum ResidentSpecialtiesUsEnum {
  DERMATOLOGO = '67',
  M_ESTETICA = '90',
}

export const registerResidentSpecialties = {
  'pin-es': ResidentSpecialtiesEsEnum,
  'pin-ar': ResidentSpecialtiesArEnum,
  'pin-co': ResidentSpecialtiesCoEnum,
  'pin-gt': ResidentSpecialtiesGtEnum,
  'pin-pe': ResidentSpecialtiesPeEnum,
  'pin-cl': ResidentSpecialtiesClEnum,
  'pin-mx': ResidentSpecialtiesMxEnum,
  'pin-pt': ResidentSpecialtiesPtEnum,
  'pin-br': ResidentSpecialtiesBrEnum,
  'pin-it': ResidentSpecialtiesItEnum,
  'pin-de': ResidentSpecialtiesDeEnum,
  'pin-us': ResidentSpecialtiesUsEnum,
};

export const registerOdontologyInfoSpecialties = {
  'pin-es': OdontologyInfoSpecialtiesEsEnum,
};
//in PE all specialities need collegiate number as optional
export enum SpecialtyCollegiatePeEnum {
  DERMATOLOGO = '67',
  M_ESTETICA = '68',
  PEDIATRA = '70',
}

export enum SpecialtyCollegiateEsEnum {
  DERMATOLOGO = '67',
  ODONTOESTOMATOLOGO = '72',
}

export enum SpecialtyCollegiateEsLengthEnum {
  DERMATOLOGO = 9,
  ODONTOESTOMATOLOGO = 8,
  HIGIENISTAS = 8,
}

export enum SpecialtyCollegiateMxLengthEnum {
  ODONTOESTOMATOLOGO = 9,
}

export enum SpecialtyCollegiateAdEnum {
  DERMATOLOGO = '67',
  ODONTOESTOMATOLOGO = '72',
}

export enum SpecialtyCollegiateClEnum {
  DERMATOLOGO = '67',
}

export enum SpecialtyCollegiateGtEnum {
  DERMATOLOGO = '67',
}

export enum SpecialtyCollegiateCoEnum {
  DERMATOLOGO = '67',
}

export enum SpecialtyCollegiateMxEnum {
  DERMATOLOGO = '67',
  M_ESTETICA = '68',
  CIRUJANO_PLASTICO = '80',
  ASISTENCIA_PRIMARIA = '71',
  ODONTOESTOMATOLOGO = '72',
}

export enum SpecialtyCollegiatePtEnum {
  DERMATOLOGO = '67',
}

export enum SpecialtyCollegiateItEnum {
  DERMATOLOGO = '67',
  M_ESTETICA = '68',
}

export enum SpecialtyCollegiateBrEnum {
  DERMATOLOGO = '67',
}

export enum SpecialtyCollegiateDeEnum {
  DERMATOLOGO = '67',
  GINECOLOGO = '69',
  PEDIATRA = '70',
  M_ESTETICA = '68',
  ASISTENCIA_PRIMARIA = '71',
}

export const registerSpecialityCollegiate = {
  'pin-es': SpecialtyCollegiateEsEnum,
  'pin-ar': SpecialtyCollegiateAdEnum,
  'pin-pe': SpecialtyCollegiatePeEnum,
  'pin-cl': SpecialtyCollegiateClEnum,
  'pin-gt': SpecialtyCollegiateGtEnum,
  'pin-co': SpecialtyCollegiateCoEnum,
  'pin-mx': SpecialtyCollegiateMxEnum,
  'pin-pt': SpecialtyCollegiatePtEnum,
  'pin-br': SpecialtyCollegiateBrEnum,
  'pin-it': SpecialtyCollegiateItEnum,
  'pin-de': SpecialtyCollegiateDeEnum,
};

export const registerSpecialityCollegiateLength = {
  'pin-es': {
    67: {
      minLength: SpecialtyCollegiateEsLengthEnum.DERMATOLOGO,
      maxLength: SpecialtyCollegiateEsLengthEnum.DERMATOLOGO,
    },
    72: {
      minLength: SpecialtyCollegiateEsLengthEnum.ODONTOESTOMATOLOGO,
      maxLength: SpecialtyCollegiateEsLengthEnum.ODONTOESTOMATOLOGO,
    },
    74: {
      minLength: SpecialtyCollegiateEsLengthEnum.HIGIENISTAS,
      maxLength: SpecialtyCollegiateEsLengthEnum.HIGIENISTAS,
    },
  },
  'pin-mx': {
    72: {
      minLength: SpecialtyCollegiateMxLengthEnum.ODONTOESTOMATOLOGO,
      maxLength: SpecialtyCollegiateMxLengthEnum.ODONTOESTOMATOLOGO,
    },
  },
};
