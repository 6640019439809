import {
  useSize,
  YuFlex,
  YuSkeleton,
  YuSkeletonWrapper,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import _ from 'lodash';
import { NextPage } from 'next';
import ConditionalRenderer from 'src/components/ConditionalRenderer';
import { SkeletonAccountLayout } from './AccountLayout.skeleton';
import { YuContainer } from '../../layout/YuContainer';

const SkeletonMyOrders: NextPage = (): JSX.Element => {
  const { upXS, upM } = useSize();

  const numberOfCards = 3;
  const numberOfImages = upXS ? 5 : 3;

  const RenderOrderImages = ({ imagesNumber }: { imagesNumber: number }) => {
    return (
      <YuFlex gap="XXS" flexDirection="row" width="100%" maxWidth={592}>
        {_.times(imagesNumber, () => (
          <YuSkeleton
            height={{ initial: 122.5, upXS: 140 }}
            width={{ initial: 100, upXS: 112 }}
            variant="text"
          />
        ))}
      </YuFlex>
    );
  };

  const renderOrderCards = (index: number) => {
    return (
      <YuSkeletonWrapper key={index}>
        <YuFlex
          flexDirection="column"
          gap="M"
          border="1px solid #f0f0f0"
          borderRadius="4px"
          padding="XS"
          width="100%"
          style={{
            boxShadow:
              '0 0 1px 0 hsla(0,0%,7%,.3), 0 2px 4px 0 hsla(0,0%,7%,.2)',
          }}
        >
          <YuFlex flexDirection="row" gap="XXS" justify="start">
            <RenderOrderImages imagesNumber={numberOfImages} />
          </YuFlex>

          <YuFlex flexDirection="row" justify="space-between">
            <YuFlex flexDirection="column" gap="XS" justify="start">
              <YuSkeleton height={16} width={120} variant="text" />
              <YuSkeleton height={16} width={60} variant="text" />
            </YuFlex>
            <YuFlex>
              <YuSkeleton height={16} width="85px" variant="text" />
            </YuFlex>
          </YuFlex>
        </YuFlex>
      </YuSkeletonWrapper>
    );
  };

  const Pagination = (
    <YuFlex flexDirection="column" maxWidth={624}>
      <YuSpacer size="M" />
      <YuFlex
        flexDirection={upXS ? 'row' : 'column'}
        justify="space-between"
        gap="S"
        alignItems={{ initial: 'center', upXS: 'start' }}
      >
        <YuSkeleton height={18} width="140px" variant="text" />
        <YuFlex flexDirection="row" gap="XS">
          <YuSkeleton height={18} width="18px" variant="text" />
          <YuSkeleton height={18} width="150px" variant="text" />
          <YuSkeleton height={18} width="18px" variant="text" />
        </YuFlex>
      </YuFlex>
    </YuFlex>
  );

  return (
    <YuContainer>
      <YuSkeletonWrapper style={{ width: '100%', margin: 'auto' }}>
        <ConditionalRenderer
          condition={!upM}
          trueComponent={
            <YuFlex
              pl={{ initial: 'XS', upM: 0 }}
              pr={{ initial: 'XS', upM: 0 }}
              flexDirection="column"
            >
              <YuSpacer size="XS" />
              <YuSkeleton
                height="24px"
                width="150px"
                variant="text"
                alignSelf="start"
              />
              <YuSpacer size="L" />
            </YuFlex>
          }
          falseComponent={<YuSpacer size="L" />}
        />
        <SkeletonAccountLayout>
          <YuFlex flexDirection="column" width="100%">
            <YuSkeletonWrapper
              width="100%"
              maxWidth={{ initial: 624, upL: '100%' }}
              ml={{ upM: 'XXL' }}
              mr={{ upM: 'XXL' }}
              pl={{ onlyXS: 'XS' }}
              pr={{ onlyXS: 'XS' }}
              alignSelf={upM ? 'start' : 'center'}
            >
              {upM && <YuSpacer size="M" />}
              <YuFlex
                flexDirection={upXS ? 'row' : 'column'}
                maxWidth={624}
                gap="M"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <YuSkeleton
                  height={32}
                  width="154px"
                  variant="text"
                  alignSelf={upXS ? 'flex-start' : 'center'}
                />
                <YuSkeleton
                  height={56}
                  width={{ initial: '100%', upXS: '162px' }}
                  variant="text"
                />
              </YuFlex>
              <YuSpacer size="S" />
              <YuFlex flexDirection="column" gap="M" maxWidth={624}>
                {_.times(numberOfCards, (index) => renderOrderCards(index))}
              </YuFlex>
              {Pagination}
              <YuSpacer size="XL" />
            </YuSkeletonWrapper>
          </YuFlex>
        </SkeletonAccountLayout>
      </YuSkeletonWrapper>
    </YuContainer>
  );
};

export default SkeletonMyOrders;
