import {
  useSize,
  YuFlex,
  YuSkeleton,
  YuSkeletonWrapper,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import _ from 'lodash';
import { NextPage } from 'next';
import ConditionalRenderer from 'src/components/ConditionalRenderer';
import { YuContainer } from '../../layout/YuContainer';

const SkeletonMyProfile: NextPage = (): JSX.Element => {
  const { upM, downM } = useSize();

  const fieldProfile = () => {
    return (
      <YuFlex gap="XXS" flexDirection="column">
        <YuSkeleton height={16} width="110px" variant="text" />
        <YuSkeleton height={16} width="151px" variant="text" />
      </YuFlex>
    );
  };

  const titleFieldProfileDesktop = () => {
    return (
      <YuFlex flexDirection="row">
        <YuFlex flexDirection="row" gap="XXS">
          <YuSkeleton height={24} width="24px" variant="text" />
          <YuSkeleton height={24} width="220px" variant="text" />
        </YuFlex>
        <YuSpacer size="S" />
        <YuSkeleton height={24} width="128px" variant="text" />
      </YuFlex>
    );
  };

  const titleFieldProfileMobile = () => {
    return (
      <YuFlex flexDirection="row" justifyContent="space-between">
        <YuFlex flexDirection="row" gap="XXS">
          <YuSkeleton height={24} width="24px" variant="text" />
          <YuSkeleton height={24} width="220px" variant="text" />
        </YuFlex>
        <YuSkeleton height={24} width="66px" variant="text" />
      </YuFlex>
    );
  };
  const DesktopHeaderDescription = () => {
    return (
      <YuContainer>
        <YuSkeletonWrapper style={{ width: '100%', margin: 'auto' }}>
          <YuSpacer size="M" />
          <YuFlex flexDirection="row">
            <YuSkeletonWrapper
              marginTop="S"
              paddingLeft="L"
              paddingRight="M"
              paddingTop="L"
              minWidth="400px"
              width="400px"
              backgroundColor="bg.grey"
            >
              <YuFlex gap="M" flexDirection="column">
                <YuContainer>
                  <YuSkeletonWrapper
                    width="96%"
                    style={{
                      width: '100%',
                      borderRadius: '4px',
                      boxShadow:
                        '0px 0px 1px 0px #1313134D, 0px 4px 8px 0px #13131333',
                    }}
                    backgroundColor={'bg.grey'}
                    padding="XS"
                  >
                    <YuFlex flexDirection="row" gap="L">
                      <YuFlex gap="XXS" flexDirection="column">
                        <YuSkeleton height={16} width="71px" variant="text" />
                        <YuSkeleton height={24} width="154px" variant="text" />
                      </YuFlex>
                      <YuSkeleton height={56} width="56px" variant="text" />
                    </YuFlex>
                  </YuSkeletonWrapper>
                </YuContainer>
                <YuFlex gap="S" flexDirection="column">
                  <YuFlex flexDirection="row" gap="XXS">
                    <YuSkeleton height={16} width="24px" variant="text" />
                    <YuSkeleton height={16} width="91px" variant="text" />
                  </YuFlex>
                  <YuFlex flexDirection="row" gap="XXS">
                    <YuSkeleton height={16} width="24px" variant="text" />
                    <YuSkeleton height={16} width="116px" variant="text" />
                  </YuFlex>
                  <YuFlex flexDirection="row" gap="XXS">
                    <YuSkeleton height={16} width="24px" variant="text" />
                    <YuSkeleton height={16} width="140px" variant="text" />
                  </YuFlex>
                  <YuFlex flexDirection="row" gap="XXS">
                    <YuSkeleton height={16} width="24px" variant="text" />
                    <YuSkeleton height={16} width="116px" variant="text" />
                  </YuFlex>
                </YuFlex>
              </YuFlex>
            </YuSkeletonWrapper>
            <YuSkeletonWrapper
              marginTop="S"
              paddingLeft="XXL"
              paddingTop="M"
              width="100%"
              maxWidth="752px"
              marginRight="L"
            >
              <YuFlex gap="M" flexDirection="column" width="100%">
                <YuSkeleton height={32} width="154px" variant="text" />
                <YuFlex gap="S" flexDirection="column">
                  {titleFieldProfileDesktop()}
                  {_.times(2, () => fieldProfile())}
                </YuFlex>
                <YuFlex gap="S" flexDirection="column" width="100%">
                  {titleFieldProfileDesktop()}
                  {_.times(4, () => fieldProfile())}
                </YuFlex>
                <YuFlex gap="S" flexDirection="column" width="100%">
                  {titleFieldProfileDesktop()}
                  {_.times(4, () => fieldProfile())}
                  <YuFlex gap="XXS" flexDirection="column">
                    <YuSkeleton height={16} width="110px" variant="text" />
                    <YuSkeleton height={16} width="249px" variant="text" />
                  </YuFlex>
                  <YuFlex gap="XXS" flexDirection="column">
                    <YuSkeleton height={16} width="110px" variant="text" />
                    <YuSkeleton height={16} width="249px" variant="text" />
                  </YuFlex>
                </YuFlex>
                <YuSkeleton height={24} width="99px" variant="text" />
                <YuSpacer size="XL" />
              </YuFlex>
            </YuSkeletonWrapper>
          </YuFlex>
        </YuSkeletonWrapper>
      </YuContainer>
    );
  };

  const MobileHeaderDescription = () => {
    return (
      <>
        <YuFlex
          flexDirection="column"
          marginTop={{ initial: 'S', upS: 0 }}
          paddingLeft="XS"
        >
          <YuSkeleton height={24} width="154px" variant="text" />
        </YuFlex>

        <YuFlex
          flexDirection="column"
          marginTop={{ initial: 'XXS', upS: 0 }}
          marginBottom="M"
          pl="XS"
          pr="XS"
          paddingBottom="L"
          width="100%"
        >
          <YuSpacer size="XS" />
          <YuFlex
            gap="L"
            flexDirection="column"
            align="center"
            justifyContent="center"
            width="100%"
            maxWidth={640}
            alignSelf={downM ? 'center' : ''}
          >
            <YuSkeleton height={32} width="154px" variant="text" />
            <YuFlex flexDirection="column" width="100%" gap="M">
              <YuFlex gap="S" flexDirection="column">
                {titleFieldProfileMobile()}
                {_.times(2, () => fieldProfile())}
              </YuFlex>
              <YuFlex gap="S" flexDirection="column">
                {titleFieldProfileMobile()}
                {_.times(4, () => fieldProfile())}
              </YuFlex>
              <YuFlex gap="S" flexDirection="column">
                {titleFieldProfileMobile()}
                {_.times(4, () => fieldProfile())}
                <YuFlex gap="XXS" flexDirection="column">
                  <YuSkeleton height={16} width="110px" variant="text" />
                  <YuSkeleton height={16} width="249px" variant="text" />
                </YuFlex>
                <YuFlex gap="XXS" flexDirection="column">
                  <YuSkeleton height={16} width="110px" variant="text" />
                  <YuSkeleton height={16} width="249px" variant="text" />
                </YuFlex>
              </YuFlex>
            </YuFlex>
            <YuSkeleton height={24} width="99px" variant="text" />
          </YuFlex>
        </YuFlex>
      </>
    );
  };

  return (
    <ConditionalRenderer
      condition={upM}
      trueComponent={DesktopHeaderDescription()}
      falseComponent={MobileHeaderDescription()}
    ></ConditionalRenderer>
  );
};

export default SkeletonMyProfile;
