import React from 'react';
import { YuCol, YuRow } from '@isdin/yuma-react-web-pin';

const YuContainer = ({ children, ...others }) => {
  return (
    <YuRow {...others}>
      <YuCol span={12}>{children}</YuCol>
    </YuRow>
  );
};

export { YuContainer };
