import {
  useSize,
  YuCol,
  YuFlex,
  YuSkeleton,
  YuSkeletonWrapper,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import _ from 'lodash';
import { NextPage } from 'next';
import { useScientificMaterialCardsMaxWidth } from '../../../hooks/useScientificMaterialCardsMaxWidth';
import { YuContainer } from '../../layout/YuContainer';

const ScientificMaterials: NextPage = (): JSX.Element => {
  const { upXS, upM } = useSize();
  const CARDS_NUMBER = 4;

  const skeletonCard = () => {
    const maxWidth = useScientificMaterialCardsMaxWidth();
    return (
      <YuFlex
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        style={{ padding: 12, maxWidth, flex: `0 0 ${maxWidth}` }}
      >
        <YuFlex flexDirection="column" className="card mkt-mat-card">
          <YuSkeleton variant="rectangular" width="100%" height={260} />
          <YuSpacer size={upM ? 'M' : 'L'} />
          <YuCol className="description" style={{ width: '100%' }}>
            <YuSkeleton variant="text" height={24} width="100%" />
            <YuSpacer size="XXS" />
            <YuSkeleton variant="text" height={24} width="80%" />
            <YuSpacer size={upM ? 'M' : 'L'} />
            <YuSkeleton variant="text" width="100%" />
            <YuSpacer size="XXS" />
            <YuSkeleton variant="text" width="100%" />
            <YuSpacer size="XXS" />
            <YuSkeleton variant="text" width="90%" />
          </YuCol>
          <YuSpacer size={upM ? 'M' : 'L'} />
        </YuFlex>
      </YuFlex>
    );
  };

  return (
    <>
      <YuContainer>
        <YuSkeletonWrapper
          style={{ width: '100%' }}
          className="yuma-custom-skeleton scientific-materials"
        >
          <YuFlex flexDirection="column">
            <YuFlex
              flexDirection="column"
              ml={{ onlyXS: 'S', upXS: 'S' }}
              mr={{ onlyXS: 'S', upXS: 'S' }}
            >
              <YuSpacer size="L" />
              <YuSkeleton
                height={40}
                variant="text"
                width={upXS ? '292px' : '100%'}
              />
              <YuSpacer size="M" />
              <YuSkeleton height={20} variant="text" width={'100px'} />
            </YuFlex>

            {_.times(4, () => {
              return (
                <YuFlex className="section-group" flexDirection="column">
                  <YuFlex
                    flexDirection="column"
                    ml={{ onlyXS: 'S', upXS: 'S' }}
                    mr={{ onlyXS: 'S', upXS: 'S' }}
                  >
                    <YuSpacer size="XL" />
                    <YuSkeleton height={30} variant="text" width={'200px'} />
                    <YuSpacer size="XS" />
                  </YuFlex>
                  <YuSkeleton height={1} variant="text" width={'100%'} />
                  <YuSpacer size="S" />
                  <YuFlex wrap="wrap" justify="flex-start">
                    {_.times(CARDS_NUMBER, () => skeletonCard())}
                  </YuFlex>
                </YuFlex>
              );
            })}

            <YuSpacer size="XL" />
          </YuFlex>
        </YuSkeletonWrapper>
      </YuContainer>
    </>
  );
};

export default ScientificMaterials;
