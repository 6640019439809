import {
  useSize,
  YuFlex,
  YuSkeleton,
  YuSkeletonWrapper,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import { NextPage } from 'next';
import { SkeletonAccountLayout } from './AccountLayout.skeleton';
import ConditionalRenderer from '../../ConditionalRenderer';
import React from 'react';
import { UserPointsCardSkeleton } from '../UserPointsCard.skeleton';
import { YuContainer } from '../../layout/YuContainer';

export const SkeletonMyPointsItem = () => {
  return (
    <YuFlex flexDirection="row" gap="M">
      <YuSkeleton height={24} width={24} />
      <YuFlex flexDirection="column" width="100%" gap="XXS">
        <YuSkeleton height={24} maxWidth={150} width="100%" />
        <YuFlex flexDirection="row" gap="XS" justifyContent="space-between">
          <YuSkeleton height={24} maxWidth={360} width="100%" />
          <YuSkeleton height={24} maxWidth={130} width="100%" />
        </YuFlex>
        <YuFlex flexDirection="row" justifyContent="space-between">
          <YuSkeleton height={16} maxWidth={70} width="100%" />
          <YuSkeleton height={16} maxWidth={110} width="100%" />
        </YuFlex>
      </YuFlex>
    </YuFlex>
  );
};

const SkeletonMyPoints: NextPage = (): JSX.Element => {
  const { upM, upXS } = useSize();

  return (
    <YuContainer>
      <YuSkeletonWrapper style={{ width: '100%', margin: 'auto' }}>
        <ConditionalRenderer
          condition={!upM}
          trueComponent={
            <YuFlex
              pl={{ initial: 'XS', upM: 0 }}
              pr={{ initial: 'XS', upM: 0 }}
              flexDirection="column"
            >
              <YuSpacer size="XS" />
              <YuSkeleton
                height="24px"
                width="150px"
                variant="text"
                alignSelf="start"
              />
              <YuSpacer size="L" />
            </YuFlex>
          }
          falseComponent={<YuSpacer size="L" />}
        />
        <SkeletonAccountLayout>
          <YuFlex
            pl={{ initial: 0, upXS: 'XS', upM: 0 }}
            pr={{ initial: 0, upXS: 'XS', upM: 0 }}
            flexDirection="column"
            width="100%"
          >
            <YuSkeletonWrapper
              width="100%"
              pl={{ onlyXS: 'XS', upM: 'XXL' }}
              pr={{ onlyXS: 'XS', upM: 'XXL' }}
            >
              {!upM ? (
                <>
                  <YuFlex justifyContent="center">
                    <YuFlex width={310}>
                      <UserPointsCardSkeleton />
                    </YuFlex>
                  </YuFlex>
                  <YuSpacer size="M" />
                  <YuSkeleton
                    height={21}
                    width={{ initial: '100%', upXS: '200px' }}
                    variant="text"
                  />
                </>
              ) : null}
              <YuSpacer size="M" />
              <YuFlex
                flexDirection={upXS ? 'row' : 'column'}
                width="100%"
                gap="M"
                justifyContent={upM ? 'space-between' : 'center'}
                alignItems="center"
              >
                <YuSkeleton
                  height={40}
                  width="164px"
                  variant="text"
                  alignSelf={upXS ? 'flex-start' : 'center'}
                />
                {upM ? (
                  <YuSkeleton
                    height={21}
                    width={{ initial: '100%', upXS: '200px' }}
                    variant="text"
                  />
                ) : null}
              </YuFlex>
              <YuFlex width="100%" flexDirection="column">
                <YuSpacer size="L" />
                <SkeletonMyPointsItem />
                <YuSpacer size="M" />
                <YuSkeleton height={1} width="100%" />
                <YuSpacer size="M" />
                <SkeletonMyPointsItem />
                <YuSpacer size="M" />
                <YuSkeleton height={1} width="100%" />
                <YuSpacer size="M" />
                <SkeletonMyPointsItem />
                <YuSpacer size="M" />
                <YuSkeleton height={1} width="100%" />
                <YuSpacer size="M" />
                <SkeletonMyPointsItem />
                <YuSpacer size="M" />
                <YuSkeleton height={1} width="100%" />
                <YuSpacer size="M" />
                <SkeletonMyPointsItem />
                <YuSpacer size="XXL" />
                <YuSkeleton height={32} maxWidth={400} width="100%" />
                <YuSpacer size="XXS" />
                <YuSkeleton height={24} maxWidth={520} width="100%" />
                <YuSpacer size="XXS" />
                <YuSkeleton
                  height={56}
                  maxWidth={upM ? 528 : '100%'}
                  width="100%"
                />
                <YuSpacer size="XS" />
                <YuFlex
                  maxWidth={upM ? 528 : '100%'}
                  width="100%"
                  justifyContent="flex-end"
                  flexDirection="row"
                >
                  <YuSkeleton
                    height={48}
                    maxWidth={upXS ? 210 : '100%'}
                    width="100%"
                  />
                </YuFlex>
              </YuFlex>
            </YuSkeletonWrapper>
          </YuFlex>
        </SkeletonAccountLayout>
      </YuSkeletonWrapper>
    </YuContainer>
  );
};

export default SkeletonMyPoints;
