import {
  ModalBehaviourProps,
  YuButton,
  YuFlex,
  YuModal,
  YuModalContent,
  YuModalFooter,
  YuParagraph,
  YuSpacer,
  YuTitle,
} from '@isdin/yuma-react-web-pin';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { useForm } from 'react-hook-form';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRootReducers } from '../../../reducers';
import { PhoneNumber } from '../../form/fields/PhoneNumber';

interface editPhoneInterface {
  phoneNumber: string;
}

const behaviour: ModalBehaviourProps = {
  close: {
    /** Close when user click into content. */
    onClickClose: true,
    /** Close when user click out of the popper area. */
    onClickOutArea: false,
    onKeyDown: [],
  },
};

export const _EditPhoneModal = ({
  isOpen,
  onCloseModal,
  handleSubmit,
  phoneNumber,
}): JSX.Element => {
  const form = useForm<editPhoneInterface>({
    mode: 'onChange',
    defaultValues: {
      phoneNumber: phoneNumber || '',
    },
  });

  return (
    <YuModal
      isOpen={isOpen}
      closable={true}
      behaviour={behaviour}
      size="M"
      onClose={onCloseModal}
    >
      <YuTitle size="S">
        <FormattedMessage id="page.redeem-points.checkout.personal-data.phone.modal.complete.title" />
      </YuTitle>
      <YuSpacer size="XS" />
      <YuParagraph size="M">
        <FormattedMessage id="contact-us.phone-hint" />
      </YuParagraph>
      <YuSpacer size="XS" />
      <YuModalContent className="dni-form-content">
        <PhoneNumber
          formControl={form.control}
          showHint={false}
          name="phoneNumber"
        />
      </YuModalContent>
      <YuModalFooter>
        <YuFlex flexDirection="column">
          <YuButton
            size="M"
            disabled={!form.formState.isValid}
            variant="primary"
            onClick={form.handleSubmit(handleSubmit)}
          >
            <FormattedMessage id="page.redeem-points.checkout.personal-data.phone.modal.complete.button" />
          </YuButton>
        </YuFlex>
      </YuModalFooter>
    </YuModal>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    user: state.auth.user,
  };
};
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

export const EditPhoneModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(_EditPhoneModal);
