import _ from 'lodash';
import { YuInputNumber } from '@isdin/yuma-react-web-pin';
import { yuFeedback } from '../../../helpers/yuFeedback';
import { Controller } from 'react-hook-form';
import React from 'react';
import { getPhonePlatformsValidatorsAndProperties } from '../../../validations/getPhonePlatformsValidatorsAndProperties';
import { getDefaultValueResidenceCountry } from '../forms/SignupForm/form.config';
import { useIntl } from 'react-intl';

export const PhoneNumber = ({
  formControl,
  showHint = true,
  name = 'phoneNumber',
}) => {
  const { formatMessage } = useIntl();
  const phoneConfig = getPhonePlatformsValidatorsAndProperties(
    getDefaultValueResidenceCountry(),
    formatMessage
  );

  return (
    <Controller
      control={formControl}
      name={name}
      rules={{
        validate: (value) => {
          let errorMessage = null;
          _.forEach(phoneConfig.validators, (rule) => {
            if (!rule.validator(value)) {
              errorMessage = rule.message;
              return false;
            }
          });
          return errorMessage;
        },
      }}
      render={({ fieldState, field: { onChange, value } }) => {
        return (
          <YuInputNumber
            name="customerPhone"
            value={value}
            feedback={yuFeedback(fieldState.error?.message, 'error')}
            isAllowed={({ formattedValue }) => {
              return formattedValue.length < phoneConfig.properties.maxLength;
            }}
            onChange={(e) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onChange(e.nativeEvent.target.value.trim().replace('-', ''));
            }}
            label={formatMessage({
              id: 'contact-us.phone',
            })}
            hint={
              showHint
                ? formatMessage({
                    id: 'contact-us.phone-hint',
                  })
                : undefined
            }
          />
        );
      }}
    />
  );
};
