import {
  useSize,
  YuFlex,
  YuSkeleton,
  YuSkeletonWrapper,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import { NextPage } from 'next';
import { SkeletonAccountLayout } from './AccountLayout.skeleton';
import ConditionalRenderer from '../../ConditionalRenderer';
import _ from 'lodash';
import React from 'react';
import { YuContainer } from '../../layout/YuContainer';

const SkeletonMyAddresses: NextPage = (): JSX.Element => {
  const { downM, upM, upXS } = useSize();

  const orderCard = (index: number) => {
    return (
      <>
        <YuSpacer size="M" />
        <YuSkeletonWrapper
          key={index}
          style={{
            boxShadow:
              '0 0 1px 0 hsla(0,0%,7%,.3), 0 2px 4px 0 hsla(0,0%,7%,.2)',
          }}
          border="1px solid #f0f0f0"
          height="124px"
          width="100%"
          padding="XS"
        >
          <YuFlex flexDirection="row" justify="space-between">
            <YuFlex flexDirection="column">
              <YuSkeleton height={20} width="212px" variant="text" />
              <YuSpacer size="XS" />
              <YuFlex flexDirection="column" gap="XXS" justify="start">
                <YuSkeleton height={13} width="100px" variant="text" />
                <YuSkeleton height={13} width="124px" variant="text" />
                <YuSkeleton height={13} width="110px" variant="text" />
              </YuFlex>
            </YuFlex>
            <YuFlex
              flexDirection="column"
              alignItems="center"
              justify="space-between"
            >
              <YuSkeleton height={25} width="60px" variant="text" />
              <YuSkeleton height={25} width="40px" variant="text" />
            </YuFlex>
          </YuFlex>
        </YuSkeletonWrapper>
      </>
    );
  };

  return (
    <>
      <YuSpacer size={upM ? 'L' : 'XS'} />
      <YuContainer>
        <YuSkeletonWrapper style={{ width: '100%', margin: 'auto' }}>
          <SkeletonAccountLayout>
            <YuSkeletonWrapper
              pl={{ initial: 'XXS', upM: 'XXL' }}
              pr="XXS"
              width="100%"
            >
              <YuFlex flexDirection="column">
                <ConditionalRenderer
                  condition={downM}
                  trueComponent={
                    <YuFlex pl="XXS">
                      <YuSkeleton
                        height="24px"
                        width="150px"
                        variant="text"
                        alignSelf="start"
                      />
                    </YuFlex>
                  }
                />
                <YuSpacer size="L" />
                <YuFlex
                  flexDirection="column"
                  alignItems={upM ? 'flex-start' : 'center'}
                >
                  <YuSkeleton height="35px" width="245px" maxWidth="100%" />
                </YuFlex>
                <YuSkeletonWrapper
                  flexDirection="column"
                  alignSelf={upM ? 'initial' : 'center'}
                  maxWidth="624px"
                  width="100%"
                >
                  {_.times(3, orderCard)}
                  <YuSpacer size="L" />
                  <YuSkeleton height="48px" width={upXS ? '235px' : '100%'} />
                </YuSkeletonWrapper>
              </YuFlex>
            </YuSkeletonWrapper>
          </SkeletonAccountLayout>
          <YuSpacer size="L" />
        </YuSkeletonWrapper>
      </YuContainer>
    </>
  );
};

export default SkeletonMyAddresses;
