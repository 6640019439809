import {
  YuButton,
  YuCardBag,
  YuFlex,
  YuParagraph,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import { NextPage } from 'next';
import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { IRootReducers } from '../../../reducers';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import redirectTo from '../../../../services/redirectTo';
import routePaths from '../../../../config/routes';
import { BasicHeaderBagContext } from './BasicHeaderBagContext';
import { IRedeemPointsProduct } from '../../../shared/models';
import { removeBagProduct } from '../../../actions/appActions';
import { ScrollWithGradient } from '../../common/components/ScrollWithGradient';
import { BannerMaximumWarningExceeded } from '../../orderLimits/bannerMaximumWarningExceeded';
import { useTotalItemsToRemoveBag } from '../../../hooks/useTotalItemsToRemoveBag';
import { addToast } from '../../../actions/toastActions';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const BasicHeaderBagItems: NextPage<Props, unknown> = ({
  nItemsCart,
  items,
  cart,
  addToast,
  removeBagProduct,
  orderRestrictions,
}): JSX.Element => {
  const thirdEl = React.useRef<HTMLDivElement>();
  const [height, setHeight] = React.useState<string | number>('auto');
  const { formatMessage } = useIntl();
  const { setBasicHeaderBagVisible } = useContext(BasicHeaderBagContext);
  const toRemove = useTotalItemsToRemoveBag({
    orderRestrictions,
    bagProducts: items,
  });

  const onClickRemoveItem = async (item: IRedeemPointsProduct) => {
    try {
      await removeBagProduct(item, cart);
    } catch (err) {
      addToast({
        message: formatMessage({ id: 'my-bag.remove.error' }),
        status: 'error',
      });
    }
  };

  const totalPoints = React.useMemo(() => {
    return _.sum(_.map(items, (item) => item.price * item.qty));
  }, [items]);

  useEffect(() => {
    if (thirdEl.current && items.length > 3) {
      setHeight(
        thirdEl.current.offsetTop +
          thirdEl.current.getBoundingClientRect().height +
          'px'
      );
    } else {
      setHeight('auto');
    }
  }, [thirdEl, items.length]);

  return (
    <YuFlex flexDirection="column">
      <YuParagraph size="XL" fw="bold">
        {formatMessage({ id: 'header.bag.title' }, { n: nItemsCart })}
      </YuParagraph>
      <YuSpacer size="XS" />
      <BannerMaximumWarningExceeded textContainerStyle={{ maxWidth: 368 }} />
      <YuSpacer size="XS" />
      <ScrollWithGradient height={height} showGradient={nItemsCart > 3}>
        {_.map(items, (item, i) => {
          return (
            <div key={item.item_id}>
              <YuSpacer size="S" />
              <YuCardBag
                ref={i === 2 ? thirdEl : undefined}
                informative
                title={{
                  as: 'h5',
                  text: <div style={{ maxWidth: 160 }}>{item.name}</div>,
                }}
                price={{
                  value: formatMessage(
                    { id: 'page.account.order-details.points' },
                    { points: item.price * item.qty }
                  ),
                }}
                image={{
                  src: item.image,
                  alt: item.name,
                  unoptimized: true,
                }}
                onRemoveFromBag={() => onClickRemoveItem(item)}
                quantity={item.qty}
              />
            </div>
          );
        })}
      </ScrollWithGradient>
      <YuSpacer size="XS" />
      <YuFlex justify="space-between">
        <YuParagraph size="XL" fw="bold">
          {formatMessage({ id: 'header.bag.total' })}
        </YuParagraph>
        <YuParagraph size="XL" fw="bold">
          {formatMessage(
            { id: 'page.account.order-details.points' },
            { points: totalPoints }
          )}
        </YuParagraph>
      </YuFlex>
      <YuSpacer size="S" />
      <YuFlex gap="XS">
        <YuButton
          variant="secondary"
          style={{ width: '50%' }}
          onClick={() => {
            redirectTo(routePaths.PAGES.REDEEM_POINTS.MY_BAG);
            setBasicHeaderBagVisible(false);
          }}
        >
          {formatMessage({ id: 'header.bag.view-bag' }).toUpperCase()}
        </YuButton>
        <YuButton
          style={{ width: '50%' }}
          disabled={!!toRemove}
          onClick={() => {
            redirectTo(routePaths.PAGES.REDEEM_POINTS.CHECKOUT);
            setBasicHeaderBagVisible(false);
          }}
        >
          {formatMessage({ id: 'header.bag.process-order' })}
        </YuButton>
      </YuFlex>
    </YuFlex>
  );
};

const mapStateToProps = (state: IRootReducers) => {
  return {
    user: state.auth.user,
    items: [...state.app.redeemPoints.cart.items].reverse(),
    nItemsCart: _.sum(_.map(state.app.redeemPoints.cart.items, 'qty')),
    cart: state.app.redeemPoints.cart,
    orderRestrictions: state.auth.user.magento?.orderRestrictions,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ removeBagProduct, addToast }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasicHeaderBagItems);
