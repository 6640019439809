import {
  useSize,
  YuDivider,
  YuFlex,
  YuSkeleton,
  YuSkeletonWrapper,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import { NextPage } from 'next';
import ConditionalRenderer from 'src/components/ConditionalRenderer';
import { YuContainer } from '../../layout/YuContainer';

const SkeletonTrainingDetails: NextPage = (): JSX.Element => {
  const { upM, upS } = useSize();

  const MobileHeaderDescription = () => {
    return (
      <YuFlex flexDirection="column" gap="S">
        <YuFlex flexDirection="column" gap="XXS">
          <YuSkeleton height={40} variant="text" width="100%" />
          <YuSkeleton height={40} variant="text" width="269px" />
        </YuFlex>

        <YuFlex flexDirection="column" gap="XXS">
          <YuSkeleton height={16} variant="text" width="100%" />
          <YuSkeleton height={16} variant="text" width="287px" />
          <YuSkeleton height={16} variant="text" width="62px" />
        </YuFlex>
      </YuFlex>
    );
  };

  const DesktopHeaderDescription = () => {
    return (
      <>
        <YuFlex flexDirection="column" gap="S" flexGrow={1} flexShrink={1}>
          <YuFlex
            gap="XS"
            flexDirection="row"
            justify="space-around"
            width="100%"
          >
            <YuSkeleton height={40} width="100%" variant="text" />
            <YuFlex width="40">
              <YuSkeleton height={40} width={40} variant="circular" />
            </YuFlex>
          </YuFlex>
          <YuFlex gap="XXS" flexDirection="column">
            <YuSkeleton height={16} width="auto" variant="text" />
            <YuSkeleton height={16} width="auto" variant="text" />
            <YuSkeleton height={16} width="50%" variant="text" />
          </YuFlex>
        </YuFlex>
      </>
    );
  };

  return (
    <>
      <YuContainer>
        <YuSkeletonWrapper
          paddingLeft="S"
          paddingRight="S"
          className="yuma-custom-skeleton"
          alignItems="center"
          width="100%"
        >
          <YuSpacer size={{ initial: 'S', upXS: 'L' }} />

          <YuFlex flexDirection="column" gap={upM ? 'M' : 'S'} width="100%">
            <YuSkeleton
              height={40}
              variant="text"
              width={{ initial: '100%', upS: 292 }}
            />
            <YuFlex
              gap="S"
              flexDirection={upM ? 'row' : 'column'}
              justify="center"
            >
              <YuSkeleton
                variant="text"
                width={{ initial: '100%', upM: 604 }}
                height={{ initial: 214, upS: 385 }}
              />
              <ConditionalRenderer
                condition={upM}
                trueComponent={DesktopHeaderDescription()}
                falseComponent={MobileHeaderDescription()}
              ></ConditionalRenderer>
            </YuFlex>
          </YuFlex>

          <YuSpacer size="L" />

          <YuDivider style={{ color: '#E1E1E1' }} />

          <YuSpacer size="L" />

          <YuFlex
            flexDirection="column"
            width={upS ? '604px' : '327px'}
            height="100%"
            gap="S"
            style={{ alignSelf: 'center' }}
            flexGrow={1}
            flexShrink={1}
          >
            <YuFlex justify="center">
              <YuSkeleton height={32} width={263} variant="text" />
            </YuFlex>

            <YuFlex flexDirection="column" gap="S">
              <YuSkeleton
                height={upS ? 385 : 214}
                width="100%"
                variant="text"
              />

              <ConditionalRenderer
                condition={upS}
                trueComponent={
                  <>
                    <YuSkeleton height={16} width={170} variant="text" />

                    <YuFlex flexDirection="column" gap="XXS">
                      <YuSkeleton height={16} width="100%" variant="text" />
                      <YuSkeleton height={16} width="50%" variant="text" />
                    </YuFlex>
                  </>
                }
                falseComponent={
                  <>
                    <YuSkeleton height={16} width={170} variant="text" />

                    <YuFlex flexDirection="column" gap="XXS">
                      <YuSkeleton height={16} width="100%" variant="text" />
                      <YuSkeleton height={16} width="100%" variant="text" />
                      <YuSkeleton height={16} width={236} variant="text" />
                    </YuFlex>
                  </>
                }
              ></ConditionalRenderer>

              <YuFlex flexDirection="column" gap="XXS">
                <YuFlex className="details-card-quiz">
                  <YuFlex>
                    <YuSkeleton
                      height={8}
                      width={upS ? 315 : 259}
                      variant="text"
                    />
                  </YuFlex>
                </YuFlex>

                <YuFlex className="details-card-quiz">
                  <YuFlex>
                    <YuSkeleton
                      height={8}
                      width={upS ? 261 : 208}
                      variant="text"
                    />
                  </YuFlex>
                </YuFlex>

                <YuFlex className="details-card-quiz">
                  <YuFlex>
                    <YuSkeleton
                      height={8}
                      width={upS ? 274 : 201}
                      variant="text"
                    />
                  </YuFlex>
                </YuFlex>

                <YuFlex className="details-card-quiz">
                  <YuFlex>
                    <YuSkeleton
                      height={8}
                      width={upS ? 385 : 299}
                      variant="text"
                    />
                  </YuFlex>
                </YuFlex>
              </YuFlex>

              <YuFlex justify={upS ? 'end' : 'center'}>
                <YuSkeleton height={32} width={170} variant="text" />
              </YuFlex>
            </YuFlex>
          </YuFlex>

          <YuSpacer size="XL" />
        </YuSkeletonWrapper>
      </YuContainer>
    </>
  );
};

export default SkeletonTrainingDetails;
