import { LoadingOutlined } from '@ant-design/icons';
import { Button, Row, Tabs } from 'antd';
import { NextPage } from 'next';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ChallengedTabsEnum } from 'src/shared/enums';
import { YuContainer } from '../layout/YuContainer';

interface OwnProps {
  pageClassName: string;
  filterComponent?: JSX.Element;
  tabs?: {
    key: ChallengedTabsEnum;
    label: string;
  }[];
  onChangeTab: (key: number) => void;
  renderChallenges: () => JSX.Element | JSX.Element[];
  showMoreComponent: JSX.Element;
  headComponent?: JSX.Element;
  challengeType?: number;
  customIdDivFilters?: string;
  shouldRenderTabs?: boolean;
}

const BuildPageChallenge: NextPage<OwnProps> = ({
  pageClassName,
  filterComponent,
  tabs,
  onChangeTab,
  renderChallenges,
  showMoreComponent,
  headComponent,
  customIdDivFilters,
  shouldRenderTabs,
}): JSX.Element => {
  const tabsComponent = tabs && (
    <div
      id={customIdDivFilters ?? 'hided-by-filter'}
      key={pageClassName}
      className={`${shouldRenderTabs ? '' : 'hidden'}`}
    >
      {tabs && shouldRenderTabs ? (
        <Tabs
          onChange={(key) => onChangeTab(Number(key))}
          defaultActiveKey={String(tabs[0].key)}
        >
          {tabs.map((tab) => (
            <Tabs.TabPane
              tab={<FormattedMessage id={tab.label} />}
              key={tab.key}
              data-testid={'challenge-tab-' + tab.key}
            />
          ))}
        </Tabs>
      ) : (
        <React.Fragment />
      )}
    </div>
  );

  return (
    <YuContainer className={`challengePage ${pageClassName}`}>
      {headComponent}
      <Row
        className="challengePage__filters challengePage__filters_desktop"
        align="middle"
      >
        {tabsComponent}
        {filterComponent && filterComponent}
      </Row>

      <Row
        className="challengePage__filters challengePage__tabs_mobile"
        align="middle"
      >
        {tabsComponent}
      </Row>

      <Row
        className="challengePage__filters challengePage__filters_mobile"
        align="middle"
        justify="end"
      >
        {filterComponent && filterComponent}
      </Row>

      <Row
        id={!tabs ? 'hided-by-filter' : null}
        justify="start"
        children={renderChallenges()}
      />

      {showMoreComponent}
    </YuContainer>
  );
};

export default BuildPageChallenge;

export const showMoreButton = (
  label: string,
  isLoading: boolean,
  hasMore: boolean,
  fetch: () => Promise<void>,
  skeleton?: React.ReactNode
): JSX.Element => {
  if (isLoading)
    return skeleton ? (
      <>{skeleton}</>
    ) : (
      <Row justify="center" style={{ padding: '1cm 0' }}>
        <LoadingOutlined />
      </Row>
    );

  return (
    hasMore && (
      <Row justify="center" style={{ padding: '1cm 0' }}>
        <Button
          className="show-more btn-ghost btn-ghost--minimalist remove-right-space"
          loading={isLoading}
          onClick={fetch}
        >
          <Row justify="center" align="middle">
            <p>
              <FormattedMessage id={label} />
            </p>
            <a className={`icon icon--arrow-dropdown-down`} />
          </Row>
        </Button>
      </Row>
    )
  );
};
