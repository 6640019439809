import { PIN_PlatformID_Enum } from 'src/shared/enums';

export const selectConfig: Record<
  PIN_PlatformID_Enum,
  { value: string; label: string }[]
> = {
  'pin-es': [
    { value: 'ES', label: 'España' },
    { value: 'AD', label: 'Andorra' },
  ],
  'pin-ar': [],
  'pin-br': [],
  'pin-cl': [],
  'pin-co': [],
  'pin-de': [
    { value: 'DE', label: 'Deutschland' },
    { value: 'AT', label: 'Österreich' },
  ],
  'pin-gt': [],
  'pin-it': [
    { value: 'IT', label: 'Italia' },
    { value: 'VA', label: 'Vaticano' },
    { value: 'SM', label: 'San Marino' },
  ],
  'pin-mx': [],
  'pin-pe': [],
  'pin-pt': [],
};
