import {
  useSize,
  YuFlex,
  YuSkeleton,
  YuSkeletonWrapper,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import _ from 'lodash';
import { NextPage } from 'next';
import ConditionalRenderer from 'src/components/ConditionalRenderer';
import { YuContainer } from '../../layout/YuContainer';
import { ChallengeListCards } from '../ChallengeListCards';

const SkeletonTraining: NextPage = (): JSX.Element => {
  const { upXS } = useSize();

  const FILTERS = 4;

  return (
    <>
      <YuContainer>
        <YuSkeletonWrapper width="100%" className="yuma-custom-skeleton">
          <YuFlex width="100%" flexDirection="column">
            <YuFlex
              flexDirection="column"
              ml={{ onlyXS: 'S', upXS: 'S' }}
              mr={{ onlyXS: 'S', upXS: 'S' }}
            >
              <YuSpacer size={{ initial: 'M', upXS: 'L' }} />
              <YuSkeleton
                height={40}
                variant="text"
                width={upXS ? '292px' : '100%'}
              />
              <YuSpacer size="L" />
            </YuFlex>

            <YuFlex
              flexDirection="column"
              mr={{ onlyXS: null, upXS: 'S' }}
              ml={{ onlyXS: 'S', upXS: 'S' }}
            >
              <YuFlex style={{ overflowX: 'hidden' }} gap="L">
                <YuFlex gap={{ upXS: 'S', onlyXS: 'XXS' }}>
                  {_.times(FILTERS, () => (
                    <YuSkeleton height={24} variant="text" width="100px" />
                  ))}
                </YuFlex>

                <YuSkeleton height={24} variant="text" width="140px" />
              </YuFlex>
            </YuFlex>

            <ConditionalRenderer
              condition={!upXS}
              trueComponent={
                <>
                  <YuSpacer size="S" />
                  <YuFlex
                    flexDirection="column"
                    mr={{ onlyXS: 'S' }}
                    ml={{ onlyXS: 'S' }}
                  >
                    <YuFlex gap="M" justify="space-between">
                      <YuSkeleton height={24} variant="text" width={100} />
                      <YuSkeleton height={24} variant="text" width={24} />
                    </YuFlex>
                  </YuFlex>
                </>
              }
            />
            <YuSpacer size="XL" />

            <ChallengeListCards />

            <YuSpacer size={upXS ? 'XL' : 'L'} />

            <YuFlex justify="center">
              <YuSkeleton variant="text" width={118} height={32} />
            </YuFlex>

            <YuSpacer size="XL" />
          </YuFlex>
        </YuSkeletonWrapper>
      </YuContainer>
    </>
  );
};

export default SkeletonTraining;
