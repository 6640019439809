import {
  useSize,
  YuCol,
  YuFlex,
  YuSkeleton,
  YuSkeletonWrapper,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import _ from 'lodash';
import { NextPage } from 'next';
import { YuContainer } from '../../layout/YuContainer';

const SkeletonMarketingMaterials: NextPage = (): JSX.Element => {
  const { upXS, upM } = useSize();
  const CARDS_NUMBER = 4;

  const skeletonCard = () => {
    return (
      <YuFlex
        flexDirection="column"
        className="card"
        width={upXS ? 'auto' : '100%'}
        maxWidth={346}
      >
        <YuSkeleton
          className="top-rounded-borders"
          variant="rectangular"
          width={{ initial: '100%', upXS: 314 }}
          height={{ initial: 260, upXS: 260 }}
        />
        <YuSpacer size={upM ? 'M' : 'L'} />
        <YuCol className="description">
          <YuSkeleton variant="text" height={24} width="100%" />
          <YuSpacer size="XXS" />
          <YuSkeleton variant="text" height={24} width="80%" />
          <YuSpacer size={upM ? 'M' : 'L'} />
          <YuSkeleton variant="text" width="100%" />
          <YuSpacer size="XXS" />
          <YuSkeleton variant="text" width="100%" />
          <YuSpacer size="XXS" />
          <YuSkeleton variant="text" width="90%" />
        </YuCol>
        <YuSpacer size={upM ? 'M' : 'L'} />
      </YuFlex>
    );
  };

  return (
    <YuContainer>
      <YuSkeletonWrapper
        style={{ width: '100%' }}
        className="yuma-custom-skeleton"
      >
        <YuFlex flexDirection="column">
          <YuFlex
            flexDirection="column"
            ml={!upM ? 'S' : 'inherit'}
            mr={!upM ? 'S' : 'inherit'}
          >
            <YuSpacer size="XL" responsive={true} />
            <YuSkeleton
              height={40}
              variant="text"
              width={upXS ? '292px' : '100%'}
            />
            <YuSpacer size="M" />
            <YuFlex justifyContent="space-between">
              <YuSkeleton
                height={20}
                variant="text"
                width="100px"
                marginTop="S"
              />
              <YuSkeleton height={20} variant="text" width="100px" />
            </YuFlex>
          </YuFlex>

          {_.times(4, () => {
            return (
              <>
                <YuFlex
                  flexDirection="column"
                  pl={!upM ? 'XS' : 'inherit'}
                  pr={!upM ? 'XS' : 'inherit'}
                >
                  <YuSpacer size="L" />
                  <YuSkeleton height={30} variant="text" width="200px" />
                  <YuSpacer size="XS" />
                  <YuSkeleton height={1} variant="text" width="100%" />
                  <YuSpacer size="S" />
                </YuFlex>
                <YuFlex
                  wrap="wrap"
                  justifyContent={{ initial: 'center', upXS: 'space-between' }}
                  gap={'S'}
                  pl={{ initial: 'XXS', upXS: 'M', upS: 'XXS' }}
                  pr={{ initial: 'XXS', upXS: 'M', upS: 'XXS' }}
                >
                  {_.times(CARDS_NUMBER, () => skeletonCard())}
                </YuFlex>
                <YuSpacer size="L" />
                <YuSkeleton
                  height={20}
                  variant="text"
                  width="100px"
                  marginTop="S"
                  alignSelf="center"
                />
              </>
            );
          })}

          <YuSpacer size="XL" />
        </YuFlex>
      </YuSkeletonWrapper>
    </YuContainer>
  );
};

export default SkeletonMarketingMaterials;
