import {
  useSize,
  YuCol,
  YuFlex,
  YuSkeleton,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import _ from 'lodash';

export const ChallengeListCards = (): JSX.Element => {
  const { upXS, upM, upL, upS } = useSize();
  const CARDS_NUMBER = 6;
  const CARDS_NUMBER_MOBILE = 4;

  const skeletonCard = () => {
    return (
      <YuFlex
        flexDirection="column"
        width={upL ? '33.3333%' : upS ? '50%' : '100%'}
        maxWidth={385}
        className="card"
      >
        <YuSkeleton
          className="top-rounded-borders"
          variant="rectangular"
          width="100%"
          maxWidth={385}
          height={{ initial: 260, upS: 260 }}
        />
        <YuSpacer size={upM ? 'M' : 'L'} />
        <YuCol className="description">
          <YuSkeleton
            variant="text"
            height={24}
            width="100%"
            maxWidth={{ initial: 295, upS: 358 }}
          />
          <YuSpacer size={upM ? 'M' : 'L'} />
          <YuSkeleton variant="text" width="100%" maxWidth={167} />
          <YuSpacer size="S" />
          <YuSkeleton variant="text" width="100%" maxWidth={167} />
          <YuSpacer size="L" />
          <YuSkeleton
            variant="rectangular"
            width="100%"
            maxWidth={123}
            height={32}
          />
        </YuCol>
        <YuSpacer size={upM ? 'M' : 'L'} />
      </YuFlex>
    );
  };
  return (
    <YuFlex
      width="100%"
      flexDirection="column"
      pl={{ upXS: 'M' }}
      pr={{ upXS: 'M' }}
    >
      <YuFlex
        justifyContent={upS ? 'space-between' : 'center'}
        className="skeleton-challenge-carousel--cards"
      >
        {_.times(upXS ? CARDS_NUMBER : CARDS_NUMBER_MOBILE, () =>
          skeletonCard()
        )}
      </YuFlex>
    </YuFlex>
  );
};
