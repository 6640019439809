import { createHash } from 'crypto';
import getConfig from 'next/config';

export interface IGA4DataLayer {
  event: string;
  location: string;
  category?: string;
  funnel_step?: string;
  user_lead_id_sha?: string;
  user_lead_id_md5?: string;
  user_id?: string;
  user_type?: 'internal' | 'external';
  user_type_pin?: string;
  error_code?: string;
  error_name?: string;
  link_url?: string;
  option?: string;
}

const { APP_ENV } = getConfig().publicRuntimeConfig;

export const pushToDataLayer = (data: IGA4DataLayer) => {
  if (
    APP_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    window.dataLayer
  ) {
    window.dataLayer.push({ ...data });
  }
};

export const getUserType = (email: string): 'internal' | 'external' => {
  return email.includes('@isdin.com') ? 'internal' : 'external';
};

export const createUserLeadIdHash = (
  algorithm: 'sha256' | 'md5',
  email: string
) => {
  return email !== undefined
    ? createHash(algorithm).update(email).digest('hex')
    : undefined;
};
