import {
  useSize,
  YuDivider,
  YuFlex,
  YuSkeleton,
  YuSkeletonWrapper,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import _ from 'lodash';
import { NextPage } from 'next';
import { SkeletonAccountLayout } from './AccountLayout.skeleton';
import { YuContainer } from '../../layout/YuContainer';

const SkeletonMyOrdersDetails: NextPage = (): JSX.Element => {
  const { upM, onlyXS } = useSize();

  const OrderDetails = () => {
    return (
      <YuSkeletonWrapper maxWidth={640}>
        <YuFlex>
          <YuSkeleton width={320} height={35} />
        </YuFlex>
        <YuSpacer size="L" responsive={false} />
        <YuFlex flexDirection="column" gap="S">
          <YuFlex flexDirection="column" gap="S">
            <YuSkeleton width={140} height={24} />
            <YuSkeleton width={150} />
          </YuFlex>
          <YuFlex flexDirection="column" gap="S">
            <YuSkeleton width={230} height={24} />
            <YuFlex flexDirection="column" gap="XXS">
              <YuSkeleton width={150} />
              <YuSkeleton width={350} />
              <YuSkeleton width={150} />
            </YuFlex>
          </YuFlex>
          <YuFlex flexDirection="column" gap="S">
            <YuSkeleton width={180} height={24} />
            <YuFlex flexDirection="column" gap="XXS">
              <YuSkeleton width={150} />
              <YuSkeleton width={160} />
              <YuSkeleton width={230} />
              <YuSkeleton width={90} />
            </YuFlex>
          </YuFlex>
        </YuFlex>
      </YuSkeletonWrapper>
    );
  };

  const OrderSummary = () => {
    return (
      <YuSkeletonWrapper maxWidth={640}>
        <YuFlex>
          <YuSkeleton width={320} height={35} />
        </YuFlex>
        <YuSpacer size="L" />
        <YuFlex flexDirection="column" gap="S">
          {_.times(4, (index) => (
            <OrderSummaryCard index={index} />
          ))}
        </YuFlex>
        <YuSpacer size="XL" />
        <YuFlex justifyContent="space-between">
          <YuSkeleton width={100} height={24} />
          <YuSkeleton width={150} height={24} />
        </YuFlex>
      </YuSkeletonWrapper>
    );
  };

  const OrderSummaryCard = ({ index }) => {
    return (
      <YuFlex
        gap="XS"
        maxWidth={640}
        width="100%"
        justifyContent="space-between"
        id={index}
      >
        <YuFlex gap="XS">
          <YuSkeleton width={80} height={100} />
          <YuFlex flexDirection="column" gap="XXS">
            <YuSkeleton width={{ initial: 140, upXS: 280 }} height={20} />
            {onlyXS && (
              <YuSkeleton width={{ initial: 140, upXS: 280 }} height={20} />
            )}
            <YuSkeleton width={160} height={16} />
            <YuSkeleton width={100} height={16} />
          </YuFlex>
        </YuFlex>
        <YuSkeleton width={80} height={20} />
      </YuFlex>
    );
  };

  const NeedHelpSkeleton = () => {
    return (
      <YuSkeletonWrapper maxWidth={640} width="100%">
        <YuFlex flexDirection="column" gap="XS" alignItems="center">
          <YuSkeleton width={{ initial: 300, upXS: 420 }} height={30} />
          <YuSkeleton width={{ initial: 250, upXS: 320 }} height={30} />
          {onlyXS && (
            <YuSkeleton width={{ initial: 140, upXS: 280 }} height={30} />
          )}
        </YuFlex>
        <YuSpacer size="L" />
        <YuSkeleton width={100} height={120} alignSelf="center" />
        <YuSpacer size="L" />
        <YuFlex flexDirection="column" gap="XS" alignItems="center">
          <YuSkeleton width={320} />
          <YuSkeleton width={{ initial: 240, upXS: 420 }} />
          {onlyXS && <YuSkeleton width={{ initial: 140, upXS: 280 }} />}
        </YuFlex>
      </YuSkeletonWrapper>
    );
  };

  const Divider = () => {
    return (
      <YuFlex flexDirection="column" maxWidth={640} width="100%">
        <YuSpacer size="XL" />
        <YuDivider variant="light" />
        <YuSpacer size="XL" />
      </YuFlex>
    );
  };

  return (
    <YuContainer>
      <YuSkeletonWrapper style={{ width: '100%', margin: 'auto' }}>
        {upM && <YuSpacer size="L" />}
        <SkeletonAccountLayout>
          <YuFlex flexDirection="column" width="100%">
            <YuFlex pl={{ initial: 'XS', upM: 'XXL' }} flexDirection="column">
              <YuSpacer size="M" />
              <YuSkeleton height={24} width={150} />
            </YuFlex>
            <YuSkeletonWrapper
              width="100%"
              maxWidth={640}
              ml={{ initial: 'XS', upM: 'XXL' }}
              mr={{ initial: 'XS', upM: 'XXL' }}
              style={{ margin: upM ? '' : 'auto' }}
            >
              <YuSpacer size="L" />
              <YuFlex
                flexDirection="column"
                width="100%"
                maxWidth={640}
                pl={!upM ? 'XS' : undefined}
                pr={!upM ? 'XS' : undefined}
              >
                <OrderDetails />
                <Divider />
                <OrderSummary />
                <Divider />
                <NeedHelpSkeleton />
              </YuFlex>
              <YuSpacer size="XL" />
            </YuSkeletonWrapper>
          </YuFlex>
        </SkeletonAccountLayout>
      </YuSkeletonWrapper>
    </YuContainer>
  );
};

export default SkeletonMyOrdersDetails;
