import {
  useSize,
  YuDivider,
  YuFlex,
  YuIcon,
  YuSkeleton,
  YuSkeletonWrapper,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import { NextPage } from 'next';
import ConditionalRenderer from 'src/components/ConditionalRenderer';
import { YuContainer } from '../../layout/YuContainer';

const SkeletonTalkDetails: NextPage = (): JSX.Element => {
  const { upM, upS } = useSize();

  const MobileHeaderDescription = () => {
    return (
      <YuFlex flexDirection="column" gap="S">
        <YuFlex flexDirection="column" gap="XXS">
          <YuSkeleton height={40} variant="text" width="100%" />
          <YuSkeleton height={40} variant="text" width="269px" />
        </YuFlex>

        <YuFlex flexDirection="column" gap="XXS">
          <YuSkeleton height={16} variant="text" width="100%" />
          <YuSkeleton height={16} variant="text" width="90%" />
          <YuSkeleton height={16} variant="text" width="50%" />
          <YuSpacer size="XXXS" />
          <YuFlex gap="XS" flexDirection="column">
            <YuFlex gap="XXS" flexDirection="row">
              <YuSkeleton height={16} width="24px" variant="text" />
              <YuSkeleton height={16} width="92px" variant="text" />
            </YuFlex>
            <YuFlex gap="XXS" flexDirection="row">
              <YuSkeleton height={16} width="24px" variant="text" />
              <YuSkeleton height={16} width="92px" variant="text" />
            </YuFlex>
            <YuFlex gap="XXS" flexDirection="column">
              <YuFlex gap="XXS" flexDirection="row">
                <YuSkeleton height={16} width="24px" variant="text" />
                <YuSkeleton height={16} width="100%" variant="text" />
              </YuFlex>
              <YuSkeleton height={16} width="191px" variant="text" />
            </YuFlex>
          </YuFlex>
          <YuSpacer size="XXXS" />
        </YuFlex>
      </YuFlex>
    );
  };

  const DesktopHeaderDescription = () => {
    return (
      <>
        <YuFlex flexDirection="column" gap="S" flexGrow={1} flexShrink={1}>
          <YuFlex
            gap="XS"
            flexDirection="row"
            justify="space-around"
            width="100%"
          >
            <YuSkeleton height={40} width="100%" variant="text" />
            <YuFlex width="40">
              <YuSkeleton height={40} width={40} variant="circular" />
            </YuFlex>
          </YuFlex>
          <YuFlex gap="XXS" flexDirection="column">
            <YuSkeleton height={16} width="auto" variant="text" />
            <YuSkeleton height={16} width="auto" variant="text" />
            <YuSkeleton height={16} width="50%" variant="text" />
            <YuSpacer size="XXS" />
          </YuFlex>
          <YuFlex gap="XXS" flexDirection="column">
            <YuFlex gap="XXL" flexDirection="row">
              <YuFlex gap="XXS" flexDirection="row">
                <YuSkeleton height={16} width="24px" variant="text" />
                <YuSkeleton height={16} width="140px" variant="text" />
              </YuFlex>
              <YuFlex gap="XXS" flexDirection="row">
                <YuSkeleton height={16} width="24px" variant="text" />
                <YuSkeleton height={16} width="140px" variant="text" />
              </YuFlex>
            </YuFlex>
            <YuFlex flexDirection="row" gap="XXS">
              <YuSkeleton height={16} width="24px" variant="text" />
              <YuSkeleton height={16} width="475px" variant="text" />
            </YuFlex>
          </YuFlex>
        </YuFlex>
      </>
    );
  };

  return (
    <>
      <YuContainer>
        <YuSkeletonWrapper
          paddingLeft="S"
          paddingRight="S"
          className="yuma-custom-skeleton"
          alignItems="center"
          width="100%"
        >
          <YuSpacer size="L" />

          <YuFlex flexDirection="column" gap={upM ? 'M' : 'S'} width="100%">
            <YuSkeleton
              height={40}
              variant="text"
              width={{ initial: '100%', upS: 292 }}
            />
            <YuFlex
              gap="S"
              flexDirection={upM ? 'row' : 'column'}
              justify="center"
            >
              <YuSkeleton
                variant="text"
                width={{ initial: '100%', upM: 604 }}
                height={{ initial: 214, upS: 385 }}
              />
              <ConditionalRenderer
                condition={upM}
                trueComponent={DesktopHeaderDescription()}
                falseComponent={MobileHeaderDescription()}
              ></ConditionalRenderer>
            </YuFlex>
          </YuFlex>

          <YuSpacer size="L" />

          <YuDivider style={{ color: '#E1E1E1' }} />

          <YuSpacer size="L" />

          <YuFlex
            flexDirection="column"
            width={upS ? '740px' : '327px'}
            height="100%"
            gap={upS ? 'M' : 'S'}
            style={{ alignSelf: 'center' }}
            flexGrow={1}
            flexShrink={1}
            justify="center"
          >
            <YuFlex justify="center" flexDirection="column">
              <YuFlex justify="center">
                <YuSkeleton height={56} width={56} variant="text" />
              </YuFlex>
              <YuSpacer size={upM ? 'XS' : 'S'} />
              <YuFlex justify="center">
                <YuSkeleton height={32} width={300} variant="text" />
              </YuFlex>
            </YuFlex>
            <YuFlex justify="center">
              <ConditionalRenderer
                condition={upS}
                trueComponent={
                  <>
                    <YuFlex flexDirection="column" justify="center" gap="XXS">
                      <YuSkeleton height={16} width={598} variant="text" />
                    </YuFlex>
                  </>
                }
                falseComponent={
                  <>
                    <YuFlex flexDirection="column" gap="XXS">
                      <YuSkeleton height={16} width={327} variant="text" />
                      <YuFlex justify="center">
                        <YuSkeleton height={16} width={219} variant="text" />
                      </YuFlex>
                    </YuFlex>
                  </>
                }
              ></ConditionalRenderer>
            </YuFlex>
            <YuFlex flexDirection="column">
              <YuSpacer size="XXS" />
              <YuIcon
                name="PlayCircle"
                fullWidth={true}
                size="XL"
                style={{
                  mixBlendMode: 'color-dodge',
                  position: 'absolute',
                  zIndex: '1',
                  marginTop: upS ? '180px' : '100px',
                  left: upS ? '48.5%' : '47%',
                  fontSize: '24px', // Ajusta el tamaño del icono
                  width: '50px', // Ancho deseado
                  height: '50px', // Alto deseado
                }}
              />
              <YuSkeleton
                height={upS ? 385 : 214}
                width="100%"
                variant="text"
              />
            </YuFlex>
          </YuFlex>

          <YuSpacer size="XL" />
        </YuSkeletonWrapper>
      </YuContainer>
    </>
  );
};

export default SkeletonTalkDetails;
