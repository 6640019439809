import {
  useSize,
  YuFlex,
  YuSkeleton,
  YuSkeletonWrapper,
  YuSpacer,
} from '@isdin/yuma-react-web-pin';
import { NextPage } from 'next';
import { SkeletonAccountLayout } from './AccountLayout.skeleton';
import ConditionalRenderer from '../../ConditionalRenderer';
import { YuContainer } from '../../layout/YuContainer';

const SkeletonCenterMembers: NextPage = (): JSX.Element => {
  const { downM, upM, downS } = useSize();

  const User = () => {
    return (
      <>
        <YuSkeleton height="20px" width="70px" />
        <YuSpacer size="XXS" />
        <YuSkeleton height="24px" width="140px" />
        <YuSpacer size="XXS" />
        <YuSkeleton height="24px" width="220px" />
        <YuSpacer size="XXS" />
        <YuSkeleton height="24px" width="140px" />
      </>
    );
  };

  return (
    <YuContainer>
      <YuSkeletonWrapper style={{ width: '100%', margin: 'auto' }}>
        <ConditionalRenderer
          condition={downM}
          trueComponent={
            <YuFlex pl={{ initial: 'XS', upM: 0 }} flexDirection="column">
              <YuSpacer size="XS" />
              <YuSkeleton
                height="24px"
                width="150px"
                variant="text"
                alignSelf="start"
              />
            </YuFlex>
          }
          falseComponent={<YuSpacer size="L" />}
        />
        <SkeletonAccountLayout>
          <YuSkeletonWrapper
            marginTop={{ initial: 'S', upM: 0 }}
            width="100%"
            pl={{ onlyXS: 'XS' }}
            pr={{ onlyXS: 'XS' }}
          >
            <YuFlex
              flexDirection="column"
              mt={{ initial: 0, upM: 'M' }}
              ml={{ initial: 0, upM: 'XXL' }}
            >
              <YuSpacer responsive={downS ? true : false} size="XS" />
              <YuFlex
                flexDirection="column"
                alignItems={upM ? 'flex-start' : 'center'}
              >
                <YuSkeleton height="35px" width="150px" maxWidth="100%" />
              </YuFlex>
              <YuFlex
                flexDirection="column"
                justifyContent="center"
                width="100%"
                maxWidth={640}
                alignSelf={downM ? 'center' : ''}
                pl={{ initial: 0, upM: 'XS' }}
                pr={{ initial: 0, upM: 'XS' }}
              >
                <YuSpacer size="L" />
                <YuSkeleton height="24px" width="125px" />
                <YuSpacer size="M" />
                <User />
                <YuSpacer size="S" />
                <User />
                <YuSpacer size="L" />
                <YuSkeleton height="24px" width="125px" />
                <YuSpacer size="M" />
                <User />
                <YuSpacer size="S" />
                <User />
                <YuSpacer size="S" />
                <User />
                <YuSpacer size="S" />
                <User />
              </YuFlex>
            </YuFlex>
          </YuSkeletonWrapper>
        </SkeletonAccountLayout>
        <YuSpacer size="XL" />
      </YuSkeletonWrapper>
    </YuContainer>
  );
};

export default SkeletonCenterMembers;
